import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

const DatasourceFuse5 = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        SetReady()
      }, []); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }      

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.Url
            && props.IntegrationSettings.DatasourceSettings.ApiKey
            && (props.IntegrationSettings.DatasourceSettings.IncludeAccounts 
                || props.IntegrationSettings.DatasourceSettings.IncludeContacts
                || props.IntegrationSettings.DatasourceSettings.IncludeLeads))
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">URL <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Url || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Url")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("API key").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.ApiKey || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ApiKey")} className="wizardinputfield"/></div>
            <br/>
            <input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeAccounts ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeAccounts")}/> {Translate("Include accounts")}<br/>
            <input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeContacts ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeContacts")}/> {Translate("Include contacts")}<br/>
            <input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.IncludeLeads ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "IncludeLeads")}/> {Translate("Include leads")}<br/>
        </div>
    );
}

DatasourceFuse5.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceFuse5