import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceGSuite = (props) => {

    const AuthUrl = "https://accounts.google.com/o/oauth2/v2/auth" +
        "?client_id=1060468872468-8evhscaekclf4anai33tmu33kutoh3nc.apps.googleusercontent.com" +
        "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
        "&response_type=code" +
        "&access_type=offline" +
        "&prompt=consent" +
        "&scope=https://www.googleapis.com/auth/contacts.readonly"
    const TokenUrl = "https://oauth2.googleapis.com/token" 
    const TokenBody = "client_id=1060468872468-8evhscaekclf4anai33tmu33kutoh3nc.apps.googleusercontent.com&client_secret=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&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)
}

DatasourceGSuite.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceGSuite