import React, {useState, useEffect} from "react"
import PropTypes from 'prop-types'
import axios from "axios";
import { Button } from "react-bootstrap"
import { Translate } from "../Translator"
import { ConfigurationApiDatasourceData } from "../../ExternalApi/ConfigurationApi.js"
import { GetDatasourceUnavailable, GetDatasourceName, GetDatasourceLive, GetDatasourceAutoCheckSettingsWhenReady } from "../Datasources/Datasources.jsx"
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";
import IntegrationWizardUnavailableDatasource from "./IntegrationWizardUnavailableDatasource.jsx";

import DatasourceAct from "../Datasources/DatasourceAct.jsx"
import DatasourceActEssentials from "../Datasources/DatasourceActEssentials.jsx"
import DatasourceActiveCampaign from "../Datasources/DatasourceActiveCampaign.jsx"
import DatasourceAfasOnline from "../Datasources/DatasourceAfasOnline.jsx"
import DatasourceAfasProfit from "../Datasources/DatasourceAfasProfit.jsx"
import DatasourceArchieLIVE from "../Datasources/DatasourceArchieLIVE.jsx"
import DatasourceAuditCaseLIVE from "../Datasources/DatasourceAuditCaseLIVE.jsx"
import DatasourceAutotask from "../Datasources/DatasourceAutotask.jsx"
import DatasourceBitrix24 from "../Datasources/DatasourceBitrix24.jsx"
import DatasourceBullhorn from "../Datasources/DatasourceBullhorn.jsx"
import DatasourceBusinessCentral from "../Datasources/DatasourceBusinessCentral.jsx"
import DatasourceCarerix from "../Datasources/DatasourceCarerix.jsx"
import DatasourceChatwoot from "../Datasources/DatasourceChatwoot.jsx"
import DatasourceClio from "../Datasources/DatasourceClio.jsx"
import DatasourceComelitLIVE from "../Datasources/DatasourceComelitLIVE.jsx"
import DatasourceConnectWise from "../Datasources/DatasourceConnectWise.jsx"
import DatasourceDPlan from "../Datasources/DatasourceDPlan.jsx"
import DatasourceDynamicsEmpireLIVE from "../Datasources/DatasourceDynamicsEmpireLIVE.jsx"
import DatasourceDynamicsNavision from "../Datasources/DatasourceDynamicsNavision.jsx"
import DatasourceEfficy from "../Datasources/DatasourceEfficy.jsx"
import DatasourceExactOnline from "../Datasources/DatasourceExactOnline.jsx"
import DatasourceInsightly from "../Datasources/DatasourceInsightly.jsx"
import DatasourceFasterForwardElements from "../Datasources/DatasourceFasterForwardElements.jsx"
import DatasourceFileVine from "../Datasources/DatasourceFileVine.jsx"
import DatasourceFlexkidsLIVE from "../Datasources/DatasourceFlexkidsLIVE.jsx"
import DatasourceForthLIVE from "../Datasources/DatasourceForthLIVE.jsx"
import DatasourceFreshdesk from "../Datasources/DatasourceFreshdesk.jsx"
import DatasourceFreshService from "../Datasources/DatasourceFreshService.jsx"
import DatasourceFront from "../Datasources/DatasourceFront.jsx"
import DatasourceFuse5 from "../Datasources/DatasourceFuse5.jsx"
import DatasourceFysioManager from "../Datasources/DatasourceFysioManager.jsx"
import DatasourceGarageSoftware from "../Datasources/DatasourceGarageSoftware.jsx"
import DatasourceGoogleMapsLIVE from "../Datasources/DatasourceGoogleMapsLIVE.jsx"
import DatasourceGripp from "../Datasources/DatasourceGripp.jsx"
import DatasourceGSuite from "../Datasources/DatasourceGSuite.jsx"
import DatasourceHaloPSA from "../Datasources/DatasourceHaloPSA.jsx"
import DatasourceHighrise from "../Datasources/DatasourceHighrise.jsx"
import DatasourceHubspot from "../Datasources/DatasourceHubspot.jsx"
import DatasourceInfusionSoft from "../Datasources/DatasourceInfusionSoft.jsx"
import DatasourceIntercom from "../Datasources/DatasourceIntercom.jsx"
import DatasourceKolibriLIVE,{ KolibriLIVE_Finalize } from "../Datasources/DatasourceKolibriLIVE.jsx"
import DatasourceLeadSquared from "../Datasources/DatasourceLeadSquared.jsx"
import DatasourceLegalsense from "../Datasources/DatasourceLegalsense.jsx"
import DatasourceLightspeed from "../Datasources/DatasourceLightspeed.jsx"
import DatasourceLime from "../Datasources/DatasourceLime.jsx"
import DatasourceMicrosoftDynamicsOnline from "../Datasources/DatasourceMicrosoftDynamicsOnline.jsx"
import DatasourceMoneybird from "../Datasources/DatasourceMoneybird.jsx"
import DatasourceMyPhoner from "../Datasources/DatasourceMyPhoner.jsx"
import DatasourceNanny4All from "../Datasources/DatasourceNanny4All.jsx"
import DatasourceNxtPeopleLIVE from "../Datasources/DatasourceNxtPeopleLIVE.jsx"
import DatasourceOdoo from "../Datasources/DatasourceOdoo.jsx"
import DatasourceOffice from "../Datasources/DatasourceOffice.jsx"
import DatasourceOpenDental from "../Datasources/DatasourceOpenDental.jsx"
import DatasourceOutreach from "../Datasources/DatasourceOutreach.jsx"
import DatasourceOutSmart from "../Datasources/DatasourceOutSmart.jsx"
import DatasourcePerfectViewOnline from "../Datasources/DatasourcePerfectViewOnline.jsx"
import DatasourcePipedrive from "../Datasources/DatasourcePipedrive.jsx"
import DatasourcePipelineDeals from "../Datasources/DatasourcePipelineDeals.jsx"
import DatasourceQuickBase from "../Datasources/DatasourceQuickBase.jsx"
import DatasourceQuickBooks from "../Datasources/DatasourceQuickBooks.jsx"
//import DatasourceRedtail,{ Redtail_Finalize } from "../Datasources/DatasourceRedtail.jsx"
import DatasourceRPMTelco from "../Datasources/DatasourceRPMTelco.jsx"
import DatasourceSageCRM from "../Datasources/DatasourceSageCRM.jsx"
import DatasourceSalesforce from "../Datasources/DatasourceSalesforce.jsx"
import DatasourceSandayLIVE from "../Datasources/DatasourceSandayLIVE.jsx"
import DatasourceSharpspring from "../Datasources/DatasourceSharpspring.jsx"
import DatasourceSimplicate from "../Datasources/DatasourceSimplicate.jsx"
import DatasourceSnelStart from "../Datasources/DatasourceSnelStart.jsx"
import DatasourceSugarCRMOnline from "../Datasources/DatasourceSugarCRMOnline.jsx"
import DatasourceSyncroMSPLIVE from "../Datasources/DatasourceSyncroMSPLIVE.jsx"
import DatasourceTeamleader from "../Datasources/DatasourceTeamleader.jsx"
import DatasourceTheOfficeManager from "../Datasources/DatasourceTheOfficeManager.jsx"
import DatasourceTimLIVE from "../Datasources/DatasourceTimLIVE.jsx"
import DatasourceTopdesk from "../Datasources/DatasourceTopdesk.jsx"
import DatasourceTrengo from "../Datasources/DatasourceTrengo.jsx"
import DatasourceTribeCRM from "../Datasources/DatasourceTribeCRM.jsx"
import DatasourceVTiger from "../Datasources/DatasourceVTiger.jsx"
import DatasourceWealthbox from "../Datasources/DatasourceWealthbox.jsx"
import DatasourceWebCRM from "../Datasources/DatasourceWebCRM.jsx"
import DatasourceWeClapp from "../Datasources/DatasourceWeClapp.jsx"
import DatasourceWorkbooks from "../Datasources/DatasourceWorkbooks.jsx"
import DatasourceZendesk from "../Datasources/DatasourceZendesk.jsx"
import DatasourceZoho from "../Datasources/DatasourceZoho.jsx"
import DatasourceZohoBigin from "../Datasources/DatasourceZohoBigin.jsx"
import DatasourceZohoDesk from "../Datasources/DatasourceZohoDesk.jsx"
import DatasourceTest from "../Datasources/DatasourceTest.jsx"

import "../../css/IntegrationWizard.css";

const IntegrationWizardStepDatasourceSettings = (props) => {
    
    const [mRepaint, SetRepaint] = useState(false) //used only as a repainter    
    const [mSettingsReady, SetSettingsReady] = useState(false) 

    useEffect(() => {
        props.OnNextButtonEnabled(mSettingsReady && (props.IntegrationSettings.NumberSettings.CountryCode ? true : false))
        
        if (!props.IntegrationSettings.NumberSettings.CountryCode)
        {
            getGeoInfo()
        }
      }, []); 

    const getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            UpdateCountryCode(response.data.country_calling_code.replace("+",""))
        }).catch((error) => {
            console.log(error);
        });
    };

    const DatasourceSetReady = (value) =>
    {
        const SettingsReady = value ? true : false
        SetSettingsReady(SettingsReady)        
        props.OnNextButtonEnabled(SettingsReady && (props.IntegrationSettings.NumberSettings.CountryCode ? true : false))
    }

    const UpdateCountryCode = (value) =>
    {
        props.IntegrationSettings.NumberSettings.CountryCode = value.trim()
        props.OnNextButtonEnabled(mSettingsReady && (props.IntegrationSettings.NumberSettings.CountryCode ? true : false))

        SetRepaint(!mRepaint)
    }

    const UpdateSynchronizationSettings = (newValue) =>
    {
        props.IntegrationSettings.AutoUpdateFlags = newValue
        
        SetRepaint(!mRepaint)
    }  

    //Make step
    const DatasourceSettings = 
        props.IntegrationSettings.DatasourceType === "Test" ? <DatasourceTest SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Act" ? <DatasourceAct SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "actessentials simple" ? <DatasourceActEssentials SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "activecampaign simple" ? <DatasourceActiveCampaign SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "afasonline simple" ? <DatasourceAfasOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "afas simple" ? <DatasourceAfasProfit SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Archie LIVE" ? <DatasourceArchieLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "AuditCase LIVE" ? <DatasourceAuditCaseLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Autotask" ? <DatasourceAutotask SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Bitrix24" ? <DatasourceBitrix24 SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Bullhorn" ? <DatasourceBullhorn SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "BusinessCentral" ? <DatasourceBusinessCentral SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "carerix" ? <DatasourceCarerix SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Chatwoot" ? <DatasourceChatwoot SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Clio" ? <DatasourceClio SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "connectwise simple" ? <DatasourceConnectWise SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "DPlan" ? <DatasourceDPlan SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Comelit LIVE" ? <DatasourceComelitLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "DynamicsEmpire LIVE" ? <DatasourceDynamicsEmpireLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "DynamicsNavision" ? <DatasourceDynamicsNavision SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Efficy" ? <DatasourceEfficy SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "ExactOnline" ? <DatasourceExactOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        /*legacy*/props.IntegrationSettings.DatasourceType === "exactonline simple" ? <DatasourceExactOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "FasterForwardElements" ? <DatasourceFasterForwardElements SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "FileVine" ? <DatasourceFileVine SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Flexkids LIVE" ? <DatasourceFlexkidsLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Forth LIVE" ? <DatasourceForthLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "freshdesk simple" ? <DatasourceFreshdesk SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "freshservice simple" ? <DatasourceFreshService SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Front" ? <DatasourceFront SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "fuse5 simple" ? <DatasourceFuse5 SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "FysioManager" ? <DatasourceFysioManager SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "GarageSoftware" ? <DatasourceGarageSoftware SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        //Temporarily disabled -- props.IntegrationSettings.DatasourceType === "GoogleMaps LIVE" ? <DatasourceGoogleMapsLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Gripp" ? <DatasourceGripp SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "GSuite" ? <DatasourceGSuite SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "HaloPSA" ? <DatasourceHaloPSA SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "highrise simple" ? <DatasourceHighrise SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "hubspot simple" ? <DatasourceHubspot SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Infusionsoft" ? <DatasourceInfusionSoft SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Insightly" ? <DatasourceInsightly SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Intercom" ? <DatasourceIntercom SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Kolibri LIVE" ? <DatasourceKolibriLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Legalsense" ? <DatasourceLegalsense SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "LeadSquared" ? <DatasourceLeadSquared SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Lightspeed" ? <DatasourceLightspeed SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Lime" ? <DatasourceLime SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Moneybird" ? <DatasourceMoneybird SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "msdcrmonline odata simple" ? <DatasourceMicrosoftDynamicsOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "myphoner" ? <DatasourceMyPhoner SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Nanny4All" ? <DatasourceNanny4All SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "NxtPeople LIVE" ? <DatasourceNxtPeopleLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Odoo" ? <DatasourceOdoo SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Office" ? <DatasourceOffice SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "OpenDental" ? <DatasourceOpenDental SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Outreach" ? <DatasourceOutreach SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "OutSmart" ? <DatasourceOutSmart SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "perfectviewonline simple" ? <DatasourcePerfectViewOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "pipedrive simple" ? <DatasourcePipedrive SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "pipelinedeals simple" ? <DatasourcePipelineDeals SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "QuickBase" ? <DatasourceQuickBase SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "QuickBooks" ? <DatasourceQuickBooks SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        //props.IntegrationSettings.DatasourceType === "Redtail" ? <DatasourceRedtail SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "rpmtelco simple" ? <DatasourceRPMTelco SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "SageCRM" ? <DatasourceSageCRM SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "salesforce simple" ? <DatasourceSalesforce SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Sanday LIVE" ? <DatasourceSandayLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Sharpspring" ? <DatasourceSharpspring SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "simplicate simple" ? <DatasourceSimplicate SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "SnelStart" ? <DatasourceSnelStart SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "SugarCRMOnline" ? <DatasourceSugarCRMOnline SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "SyncroMSP LIVE" ? <DatasourceSyncroMSPLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Teamleader" ? <DatasourceTeamleader SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "TheOfficeManager" ? <DatasourceTheOfficeManager SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Tim LIVE" ? <DatasourceTimLIVE SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "topdesk simple" ? <DatasourceTopdesk SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Trengo" ? <DatasourceTrengo SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "tribecrm" ? <DatasourceTribeCRM SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "vtiger simple" ? <DatasourceVTiger SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Wealthbox" ? <DatasourceWealthbox SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "webCRM" ? <DatasourceWebCRM SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "WeClapp" ? <DatasourceWeClapp SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Workbooks" ? <DatasourceWorkbooks SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "Zendesk" ? <DatasourceZendesk SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "zendesklegacy simple" ? <DatasourceZendesk SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "zohocrm simple" ? <DatasourceZoho SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "ZohoBigin" ? <DatasourceZohoBigin SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "zohocrm2 simple" ? <DatasourceZoho SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        props.IntegrationSettings.DatasourceType === "ZohoDesk" ? <DatasourceZohoDesk SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} OnSetReady={DatasourceSetReady}/> :
        <IntegrationWizardUnavailableDatasource ApplicationName={props.IntegrationSettings.WizardSettings.application}/>

    let Header = props.Header 
    if (GetDatasourceUnavailable(props.IntegrationSettings.DatasourceType))
    {
        Header = Translate("Unavailable in the Webconfigurator")
    }
    else if (!Header)
    {
        Header = Translate("Configure the $DatasourceName connection").replace("$DatasourceName",GetDatasourceName(props.IntegrationSettings.DatasourceType))
    }

    //The text "Checking CRM settings" is technically also given as an error, but it will be presented as green text instead of red
    const DatasourceSettingsErrorIsNormalMessage = props.DatasourceSettingsError === "Checking CRM settings"

    const BrandingColorLight = sessionStorage.getItem("BrandingColorLight")
    const BrandingColorLightFont = sessionStorage.getItem("BrandingColorLightFont")

    return (
        <div>
            <IntegrationWizardStepHeader Header={Header}/>

            <table width="100%" cellPadding="20" cellSpacing="0">
            <tbody>
            <tr>
                <td className="wizardoutercell" style={{ backgroundColor: BrandingColorLight, color: BrandingColorLightFont }}>
                    {DatasourceSettings}

                    {(props.DatasourceSettingsError ? <span className={DatasourceSettingsErrorIsNormalMessage ? "datasourceerrorinfo" : "datasourceerror"}>{Translate(props.DatasourceSettingsError)}</span> : null)}  
                </td>
            </tr>
            </tbody>
            </table>

            {GetDatasourceUnavailable(props.IntegrationSettings.DatasourceType) || GetDatasourceLive(props.IntegrationSettings.DatasourceType) ? null :
                <span>
                    <br/>

                    <table width="100%" cellPadding="20" cellSpacing="0">
                    <tbody>
                    <tr>
                        <td className="wizardoutercell" style={{ backgroundColor: BrandingColorLight, color: BrandingColorLightFont}}>
                            <div className="wizardfieldname">{Translate("Country code").toUpperCase()} <span className="redtext">*</span></div>
                            <div className="wizardfieldvalue"><input placeholder="" type="text" value={props.IntegrationSettings.NumberSettings.CountryCode || ""} onChange={evt => UpdateCountryCode(evt.target.value)} className="wizardinputfieldcountrycode" size="10"/><span className="wizardcountrycodeplus">+</span></div>
                        </td>
                    </tr>
                    </tbody>
                    </table> 

                    <br/>

                    <table width="100%" cellPadding="20" cellSpacing="0">
                    <tbody>
                    <tr>
                        <td className="wizardoutercell" style={{ backgroundColor: BrandingColorLight, color: BrandingColorLightFont}}>
                            <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "day"} value="day" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> {Translate("Automatically synchronize every day")}<br/>
                            <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "week"} value="week" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> {Translate("Automatically synchronize every week")}<br/>
                            <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "none"} value="none" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> {Translate("Only synchronize manually")}
                        </td>
                    </tr>
                    </tbody>
                    </table> 
                </span>}
                
        </div>  
    )
}

IntegrationWizardStepDatasourceSettings.propTypes = {
    SecurityToken: PropTypes.string,
    IntegrationSettings: PropTypes.object,
    Header: PropTypes.string,  
    SubHeader: PropTypes.string,
    DatasourceSettingsError: PropTypes.string, 
    OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepDatasourceSettings