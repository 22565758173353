import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceMoneybird = (props) => {

    const AuthUrl = "https://moneybird.com/oauth/authorize" +
        "?client_id=bfa20a6ff90f7e321fc891e91702df7c" +
        "&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2" +
        "&response_type=code"
    const TokenUrl = "https://moneybird.com/oauth/token" 
    const TokenBody = "{\"client_id\":\"bfa20a6ff90f7e321fc891e91702df7c\",\"client_secret\":\"7fcaecc59900a553b7ceaeb1f60b18d2284656328f6c11d0bf650492e2c215fd\",\"grant_type\":\"authorization_code\",\"redirect_uri\":\"https://auth.cloudcti.nl/webapi/oauth2\",\"code\":\"{code}\"}" 
    const TokenBodyType = "application/json"
    const TokenCodeInBody = false
    const TokenCodeReplace = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} TokenCodeReplace={TokenCodeReplace} OnSetReady={props.OnSetReady}/>)

}

DatasourceMoneybird.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceMoneybird