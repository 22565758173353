import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

//Not a standard datasource because Application is a dropdown 

const DatasourceSandayLIVE = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        if (!props.IntegrationSettings.DatasourceSettings.Application)
        {
            props.IntegrationSettings.DatasourceSettings.Application = "Sanday huisarts"
        }
        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        if (aIdentifier === "OrganizationID")
        {
            props.IntegrationSettings.DatasourceSettings.OrganizationID = aNewValue
        }
        else if (aIdentifier === "Domain")
        {
            props.IntegrationSettings.DatasourceSettings.Domain = aNewValue
        }
        else if (aIdentifier === "Application")
        {
            props.IntegrationSettings.DatasourceSettings.Application = aNewValue
        }

        SetReady()
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.OrganizationID 
            && props.IntegrationSettings.DatasourceSettings.Domain
            && props.IntegrationSettings.DatasourceSettings.Application)
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Organization ID").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.OrganizationID || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "OrganizationID")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Domain").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Domain || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Domain")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Application").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue">
                <select name="Application" onChange={evt => UpdateDatasourceSetting(evt.target.value, "Application")} className="wizardinputfield">
                    <option value="Sanday huisarts" selected={props.IntegrationSettings.DatasourceSettings.Application === "Sanday huisarts"}>Sanday huisarts</option>
                    <option value="Sanday apotheek" selected={props.IntegrationSettings.DatasourceSettings.Application === "Sanday apotheek"}>Sanday apotheek</option>
                </select>    
            </div> 
        </div>
    );
}

DatasourceSandayLIVE.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceSandayLIVE