import React, {useCallback, useEffect, useState, useRef} from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';
import { Button } from "react-bootstrap";
import { OAuthApiStartPolling, OAuthApiStopPolling } from "../../ExternalApi/OAuthApi"
import { ConfigurationApiForward } from "../../ExternalApi/ConfigurationApi"
import IntegrationWizardDots from "../IntegrationWizard/IntegrationWizardDots"

const DatasourceClio = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 
    const [mWaitingForState, SetWaitingForState] = useState("")
    const [mError, SetError] = useState("") 
    const [mOAuthWindow, SetOAuthWindow] = useState(null) 

    const cRedirectUrl = "https://auth.cloudcti.nl/webapi/oauth2"

    useEffect(() => {

        if ((props.IntegrationSettings.DatasourceSettings["Domain"] === "")
            || (props.IntegrationSettings.DatasourceSettings["Domain"] === null)
            || (props.IntegrationSettings.DatasourceSettings["Domain"] === undefined))
        {
            props.IntegrationSettings.DatasourceSettings["Domain"] = "US"
        }

        SetReady()
        SetRepainter(!mRepainter)
      }, []); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue
        SetRepainter(!mRepainter)

        SetReady()
    }

    const SetReady = () => 
    {
        props.OnSetReady(props.IntegrationSettings.DatasourceSettings.Domain 
            && props.IntegrationSettings.DatasourceSettings.ClientID
            && props.IntegrationSettings.DatasourceSettings.ClientSecret
            && props.IntegrationSettings.DatasourceSettings.RefreshToken)
    }

    const GetDomainBaseUrl = () => {
        switch(props.IntegrationSettings.DatasourceSettings.Domain)
        {
            case "US": return "https://app.clio.com";
            case "EU": return "https://eu.app.clio.com";
            case "CA": return "https://ca.app.clio.com";
            case "AU": return "https://au.app.clio.com";
            default: return "https://app.clio.com";
        }
    }

    async function GetTokens(aAuthorizationCode) 
    {
        let Result = await ConfigurationApiForward(
            props.SecurityToken, 
            GetDomainBaseUrl() + "/oauth/token", 
            "POST", 
            "grant_type=authorization_code&client_id=" + props.IntegrationSettings.DatasourceSettings.ClientID + "&client_secret=" + props.IntegrationSettings.DatasourceSettings.ClientSecret + "&code=" + aAuthorizationCode + "&redirect_uri=" + cRedirectUrl,
            "application/json", 
            "application/x-www-form-urlencoded")
        if (Result != null)
        {
            UpdateDatasourceSetting(Result.data.refresh_token, "RefreshToken")
        }
        else
        {
            SetError("Failed to retrieve tokens")
        }

        SetWaitingForState("")
    }

    const OAuthPollResult = (aResult, aValue, aOAuthWindow) =>
    {
        try 
        { 
            if (aOAuthWindow !== null)
            {
                aOAuthWindow.close() 
                aOAuthWindow = null
                SetOAuthWindow(null)
            }
        } 
        catch {}

        if (aResult)
        {
            console.log("OAuthPollResult success: " + JSON.stringify(aValue))
            if (aValue.Code && aValue.ResponseUrl)
            {
                GetTokens(aValue.Code)
            }
            else
            {
                SetError("Failed to authenticate. No authorizationcode found")  
            }
        }
        else
        {
            console.log("OAuthPollResult error: " + JSON.stringify(aValue))  
            SetError("Failed to authenticate")          
        }
    }

    const RandomState = () => 
    {
        let Result = "client2020WEB"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

    const StartOAuth = () =>
    {
        const State = RandomState()

        const OAuthUrl = "https://app.clio.com/oauth/authorize" +
            "?client_id=" + props.IntegrationSettings.DatasourceSettings.ClientID + 
            "&redirect_uri=" + cRedirectUrl +
            "&response_type=code" +
            "&state=" + State
        const OAuthWindow = window.open(OAuthUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
        SetOAuthWindow(OAuthWindow)
        OAuthApiStartPolling(State, OAuthPollResult, OAuthWindow)

        SetWaitingForState(State)
    }

    const StopOAuth = () =>
    {
        OAuthApiStopPolling()
        try 
        { 
            if (mOAuthWindow !== null)
            {
                mOAuthWindow.close() 
                SetOAuthWindow(null)
            }
        } 
        catch {}        

        SetWaitingForState("")
    }

    const OAuthBody = props.IntegrationSettings.DatasourceSettings.RefreshToken ?
        //Authorization OK
        <div className="scriptitembody">
            {Translate("$Application connection token received succesfully").replace("$Application","Clio")}
        </div> :
        (mError ?
            //Authorization failed. Show error and connect button
            <div className="scriptitembody">
                <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button><br/>
                <div>{mError}</div>
            </div> :
            (mWaitingForState ?
                //Waiting for authorization. Show dot counter and cancel button
                <div className="scriptitembody">
                    <Button variant="danger" onClick={() => StopOAuth()} type="submit" className="">{Translate("Cancel")}</Button><br/><br/>
                    <div>{Translate("Waiting for OAUTH verification")}<IntegrationWizardDots/></div>
                </div> :
                (props.IntegrationSettings.DatasourceSettings.Domain && props.IntegrationSettings.DatasourceSettings.ClientID && props.IntegrationSettings.DatasourceSettings.ClientSecret? 
                    //There is a service url
                    <div className="scriptitembody">
                        <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button>
                    </div> :
                    //There is no service url
                    <div className="scriptitembody">
                        <Button variant="primary" disabled={true} type="submit" className="">{Translate("Connect")}</Button>
                    </div>)
            )
        )

    return (
        <span>
            <div className="wizardfieldname">{Translate("Domain").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue">
                <select name="Domain" onChange={evt => UpdateDatasourceSetting(evt.target.value, "Domain")} className="wizardinputfield">
                    <option value="US" selected={props.IntegrationSettings.DatasourceSettings.Domain === "US"}>United States (https://app.clio.com)</option>
                    <option value="EU" selected={props.IntegrationSettings.DatasourceSettings.Domain === "EU"}>Europe (https://eu.app.clio.com)</option>
                    <option value="CA" selected={props.IntegrationSettings.DatasourceSettings.Domain === "CA"}>Canada (https://ca.app.clio.com)</option>
                    <option value="AU" selected={props.IntegrationSettings.DatasourceSettings.Domain === "AU"}>Australia (https://au.app.clio.com)</option>
                </select>    
            </div>
            <div className="wizardfieldname">{Translate("Client ID").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="clio-clientid" type="text" size="100" value={props.IntegrationSettings.DatasourceSettings["ClientID"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ClientID")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Client secret").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="clio-clientsecret" type="text" size="100" value={props.IntegrationSettings.DatasourceSettings["ClientSecret"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ClientSecret")} className="wizardinputfield"/></div>
            {OAuthBody}
        </span>
    )
}

DatasourceClio.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceClio