import React from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "./DatasourceBaseTextFields.jsx";

const DatasourceKolibriLIVE = (props) => {

    const Fields = [
        {Identifier:"ApiKey", Translation:Translate("API key"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceKolibriLIVE.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceKolibriLIVE

export async function KolibriLIVE_Finalize(aIntegrationSettings) 
{
    try 
    {
        const result = await axios.get(
            "https://integration-va.cloudcti.nl/crm/kolibri/getRealEstateAgencyId?apikey=" + aIntegrationSettings.DatasourceSettings.ApiKey
        )

        if (result.status === 200)
        {
            aIntegrationSettings.DatasourceSettings.RealEstateAgencyId = result.data.RealEstateAgencyId
            return (result.data.RealEstateAgencyId)
        }
        else
        {
            return false
        }

    } 
    catch (ex) 
    {    
        console.log(ex)
        return false
    }
}