import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import ScriptWizard from "./ScriptWizard";
import { ConfigurationApiCheckToken,ConfigurationApiCRMScriptsGet,ConfigurationApiIntegrationGetSingle } from "../ExternalApi/ConfigurationApi";
import { SetLanguageId } from "./Translator";
import "../css/home.css";

const ScriptWizardWrapper = (props) => {

    //This wrapper performs a few actions before starting the wizard
    //These actions only have to be performed once

    //Get the parameters from the URL
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const ciParams = new URLSearchParams();
    for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
    const SecurityToken = ciParams.get('securitytoken')
    const Action = ciParams.get('action')?.toLowerCase()
    const ScriptIndex = parseInt(ciParams.get('scriptindex'))
    const After = ciParams.get('after') ?? ""
    const LanguageId = ciParams.get('languageid') ?? ""
    SetLanguageId(LanguageId)

    const [mSecurityTokenOK, SetSecurityTokenOK] = useState(-1);
    const [mPredefinedScripts, SetPredefinedScripts] = useState(undefined);
    const [mCallFieldScripts, SetCallFieldScripts] = useState(undefined);

    useEffect(() => {
      async function GetSecurityTokenOK() {
        const Result = await ConfigurationApiCheckToken(SecurityToken)
        return Result ? 1 : 0
      }

      async function GetCallFieldScripts() {
        const Result = await ConfigurationApiIntegrationGetSingle(SecurityToken,"DefaultScripts")
        return Result ? Result.Scripts : []
      }

      async function GetPredefinedScripts() {
        const Result = await ConfigurationApiCRMScriptsGet(SecurityToken)
        return Result ?? []
      }

      async function Initialize() {
        const SecurityTokenOK = await GetSecurityTokenOK(SecurityToken)
        let CallFieldScripts = []
        let PredefinedScripts = []
        if (Action === "update")
        {
          CallFieldScripts = await GetCallFieldScripts(SecurityToken)
          PredefinedScripts = await GetPredefinedScripts(SecurityToken)
        }
        
        SetSecurityTokenOK(SecurityTokenOK)
        SetCallFieldScripts(CallFieldScripts)
        SetPredefinedScripts(PredefinedScripts)
      }

      Initialize()
    }, []);

    if ((mSecurityTokenOK === -1) || (mCallFieldScripts === undefined) || (mPredefinedScripts === undefined))
    {
      return <Loading />
    }
    else
    {
      return mSecurityTokenOK === 1 ?
          <ScriptWizard SecurityToken={SecurityToken} Action={Action} Scripts={mCallFieldScripts} ScriptIndex={ScriptIndex} After={After} PredefinedScripts={mPredefinedScripts} /> :
          <div>Invalid security token</div>
    }
};

export default ScriptWizardWrapper;