import React, {useState} from "react";
import PropTypes from 'prop-types';
import IntegrationWizardStepScriptItem from './IntegrationWizardStepScriptItem.jsx'
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";
import { Translate } from "../Translator";
import { Button } from "react-bootstrap";

const IntegrationWizardStepScripts = (props) => {

    const [mScripts, SetScripts] = useState(props.IntegrationSettings.Scripts); //used only as a repainter

    //At this moment, new scripts will always be OpenWebPage 
    const OnAddScriptButtonClicked = async () => {
        let Script = {}
        Script.Name = Translate("My Custom Script")
        Script.SubHeader = ""
        Script.XmlFile = ""
        Script.XmlApplication = ""
        Script.ScriptType = "OpenWebPage"
        Script.Enabled = true
        Script.CSDFileName = ""
        Script.CSDParams = {}
        Script.WebPage = "https://www.google.nl?q=#(CallerNumber)"
        Script.IncludeRecognitionFields = true
        Script.IncludeCallFields = true
        Script.AutoExecute = false   
        Script.InitialState = "opened"   
        
        props.IntegrationSettings.Scripts = [...props.IntegrationSettings.Scripts, Script]
        SetScripts(props.IntegrationSettings.Scripts)
    }

    const OnDeleteScript = (aIndex) => {
        console.log("Delete index " + aIndex)
        const Scripts = props.IntegrationSettings.Scripts.filter((_, i) => i !== aIndex)
        props.IntegrationSettings.Scripts = Scripts
        SetScripts(props.IntegrationSettings.Scripts)
    }

    return (
        <div>
            <IntegrationWizardStepHeader Header={props.Header !== "" ? props.Header : Translate("Actions on incoming call")} SubHeader={props.SubHeader}/>

            <div>{props.IntegrationSettings.Scripts.map((script,index) => 
                <IntegrationWizardStepScriptItem key={"script" + index} Folded={script.InitialState !== "opened"} SecurityToken={props.SecurityToken} Script={script} PredefinedScript={props.PredefinedScripts.find(pscript => pscript.Id === script.XmlFile)} DatasourceFields={props.IntegrationSettings.DatasourceFields} OnDelete={() => OnDeleteScript(index)}/>)}</div>
            <br/>
            <div><Button variant="dark" onClick={() => OnAddScriptButtonClicked()} type="submit" className="wizardnextbutton">&nbsp;&nbsp;{Translate("Add script").toUpperCase()}&nbsp;&nbsp;</Button></div> 
        </div>
    );
}

IntegrationWizardStepScripts.propTypes = {
    SecurityToken: PropTypes.string,
    Header: PropTypes.string,
    SubHeader: PropTypes.string,
    IntegrationSettings: PropTypes.object,
    PredefinedScripts: PropTypes.array,
    OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepScripts