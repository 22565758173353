import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceBullhorn = (props) => {

    const AuthUrl = "https://auth.bullhornstaffing.com/oauth/authorize?client_id=2644f4fc-1374-4ea8-903f-ba9a3ce60216&response_type=code"
    const TokenUrl = "https://auth.bullhornstaffing.com/oauth/token" +
        "?grant_type=authorization_code" +
        "&client_id=2644f4fc-1374-4ea8-903f-ba9a3ce60216" +
        "&client_secret=QcH15E0t7xDRdNr4JMngtHXY05nXCNJq" +
        "&code="
    const TokenBody = "" 
    const TokenBodyType = "application/json"
    const TokenCodeInBody = false
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)

}

DatasourceBullhorn.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceBullhorn