import React from "react";
import Main from "./Components/Main";
import CRM from "./Components/CRM";
import IntegrationWizardWrapper from "./Components/IntegrationWizardWrapper";
import ScriptWizardWrapper from "./Components/ScriptWizardWrapper";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';

function App() {  
  
  let branding_signinmethod = ""
  let branding_color = "#0f9ddd"
  let branding_color_font = "#ffffff" //the font over a branding color
  let branding_color_light = "#d5e6fb"
  let branding_color_light_font = "#000000" //the font over a brandinglight color
  let branding_productname = "CloudCTI"
  if (window.location.href.startsWith("https://contactconnect.enreach.cloudcti.nl"))
  {
    branding_signinmethod = "voiceworks"
    branding_color = "#39006c"
    branding_color_font = "#ffffff"
    branding_color_light = "#ac96ff"
    branding_color_light_font = "#ffffff"
    branding_productname = "Enreach Connect"
  }
  //else if (window.location.href.startsWith("https://webconfiguratortest.cloudcti.nl"))
  //{
  //  branding_signinmethod = ""
  //  branding_color = "#e50032"
  //  branding_color_light = "#f7d5d6"
  //  branding_productname = "CloudCC"
  //}

  sessionStorage.setItem("BrandingSigninMethod", branding_signinmethod)
  sessionStorage.setItem("BrandingColor", branding_color)
  sessionStorage.setItem("BrandingColorFont", branding_color_font)
  sessionStorage.setItem("BrandingColorLight", branding_color_light)
  sessionStorage.setItem("BrandingColorLightFont", branding_color_light_font)
  sessionStorage.setItem("BrandingProductName", branding_productname)
    
  function AuthRoute({ children, ...rest }) {
    let securityToken = null
    try
    {
      //debug
      //return (<Route {...rest} render={({ location }) => children } />);

      const cookie = Cookies.get("webconfigurator")
      if (cookie)
      {
        console.log(cookie)
        securityToken = cookie
      }
    }
    catch { }

    const windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    const handoverToken = params.get("handover_token")
    try { params.delete("handover_token") } catch {}

    let windowUrlWithoutHandoverToken = window.location.origin
    if (params.size > 0)
    {
      windowUrlWithoutHandoverToken += "?" + `${params}`
    }

    if (securityToken && !handoverToken)
    {
    return (
      <Route
      {...rest}
      render={({ location }) => children }
    />
    );
    }
    else
    {   
      const redirecturl = windowUrlWithoutHandoverToken.replaceAll(":","%3A").replaceAll("/","%2F").replaceAll("&","%26")   
      window.location.href = "https://signin-va.cloudcti.nl/signin/onelogin?clientid=webconfigurator&redirecturl=" + redirecturl + (branding_signinmethod ? "&signinmethod=" + branding_signinmethod : "") + (handoverToken ? "&handover_token=" + handoverToken : "")
      return null;
    }
  }

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PublicRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          children
        }
      />
    );
  }

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute path="/IntegrationWizard">
            <IntegrationWizardWrapper />
          </PublicRoute>
          <PublicRoute path="/ScriptWizard">
            <ScriptWizardWrapper />
          </PublicRoute>
          <AuthRoute>
            <Main />
          </AuthRoute>
        </Switch>
      </div>
    </Router>
  );
}


export default App;
