import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import 'reactjs-popup/dist/index.css';

import NotificationTopPng from '../../Assets/NotificationTop.png';
import NotificationBottomPng from '../../Assets/NotificationBottom.png';

const IntegrationWizardStepClientNotificationPreview = (props) => {

  const [mRepainter, SetRepainter] = useState(false)

    const ReplaceFieldTypes = () => {
      let NewBody = [];

      for (let i = 0; i < props.IntegrationSettings.NotificationTemplate.Body.length; i++) 
      {
        let bodyLine = props.IntegrationSettings.NotificationTemplate.Body[i]

        if (bodyLine.includes("%(Name)"))
        {
            const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Name")
            if (Field.length > 0) 
            {
              bodyLine = bodyLine.replace("%(Name)","$(" + Field[0].FieldLabel + ")")
            }
            else 
            {
              bodyLine = bodyLine.replace("%(Name)","") 
            }           
        }

        if (bodyLine.includes("%(Organization)"))
        {
          const Field = props.IntegrationSettings.DatasourceFields.filter(field => field.FieldType === "Organization")
          if (Field.length > 0) 
          {
            bodyLine = bodyLine.replace("%(Organization)","$(" + Field[0].FieldLabel + ")")
          }
          else 
          {
            bodyLine = bodyLine.replace("%(Organization)","") 
          }  
        }    
        
        NewBody.push(bodyLine)
      } 

      props.IntegrationSettings.NotificationTemplate.Body = NewBody
    } 
    
    const ReplaceFields = (aLine) => {

      let bodyLine = aLine

      bodyLine = bodyLine.replace("#(Caller number)","0123456789")
      bodyLine = bodyLine.replace("#(Caller name)","John Doe")
      bodyLine = bodyLine.replace("#(Device number)","100")
      bodyLine = bodyLine.replace("#(Device name)","Employee 100")
      bodyLine = bodyLine.replace("#(Ddi number)","100")
      bodyLine = bodyLine.replace("#(Ddi name)","Ddi 100")
      bodyLine = bodyLine.replace("#(Application name)",props.IntegrationSettings.ApplicationName)

      {props.IntegrationSettings.DatasourceFields.map((field) => 
        bodyLine = bodyLine.replace("$(" + field.FieldLabel + ")",field.DefaultTestValue)
        )}      
      
      return bodyLine
    }

    ReplaceFieldTypes()
    
    const BrandingColor = sessionStorage.getItem("BrandingColor")

    return (

        <table width="533" cellPadding="0" cellSpacing="0">
        <tbody>
        <tr height="6" className="smallrow">
            <td width="100%" height="6" colSpan="3"><img src={NotificationTopPng} style={{backgroundColor: BrandingColor}}/></td>
        </tr>
        <tr>
            <td width="6" style={{backgroundColor: BrandingColor}}></td>
            <td width="521" className="notificationpreview">
                <div className="notificationpreviewheader">{props.IntegrationSettings.NotificationTemplate.Header}</div>
                {props.IntegrationSettings.NotificationTemplate.Body.map((line,index) => 
                <div className="notificationpreviewbody" key={index}>{ReplaceFields(line).trim() === "" ? <span className="graytext">{Translate("<empty line>")}</span> : ReplaceFields(line)}</div>
                )}
            </td>
            <td width="6" style={{backgroundColor: BrandingColor}}></td>
        </tr>
        <tr height="64">
            <td width="100%" colSpan="3"><img src={NotificationBottomPng} style={{backgroundColor: BrandingColor}}/></td>
        </tr>
        </tbody>
        </table>
    )
}

IntegrationWizardStepClientNotificationPreview.propTypes = {
    IntegrationSettings: PropTypes.object
}

export default IntegrationWizardStepClientNotificationPreview