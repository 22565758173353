import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import Button from 'react-bootstrap/Button';

import AddPng from '../../Assets/Add.png';
import AddCustomPng from '../../Assets/AddCustom.png';
import UnknownCRMPng from '../../Assets/UnknownCRM.png';

const IntegrationWizardStepApplicationItem = (props) => {
    return (
        props.Name ?
            <td align="center" className="wizardapplicationitemouter">

                <table width="200" height="240" cellPadding="6" cellSpacing="0" className="wizardapplicationitem" onClick={() => props.OnItemSelected(props.SecurityToken,props.Name)}>
                <tbody>
                <tr>
                    <td className="wizardapplicationitemtop" align="center">
                        <br/>
                        <img src={props.PngUrl} alt={props.Name}/>
                        <br clear="all"/><br/>
                    </td>
                </tr>
                <tr>
                    <td className="wizardapplicationitem">
                        <span>{props.Name}</span>
                    </td>
                </tr>
                <tr>
                    <td className="wizardapplicationitem">
                        <Button variant="dark" className="wizardapplicationitemadd">{Translate("Configure").toUpperCase() + " 🡢"}</Button>
                    </td>
                </tr>
                </tbody>
                </table> 

                <br clear="all"/>
            </td> 
                
            :

            <td align="center" className="wizardapplicationitemouter">

                <table width="200" height="240" cellPadding="6" cellSpacing="0" className="wizardapplicationitemblue" onClick={() => props.OnItemSelected(props.SecurityToken,props.Name)}>
                <tbody>
                <tr>
                    <td align="center">
                        <img src={UnknownCRMPng}/>
                    </td>
                </tr>
                <tr>
                    <td align="left" valign="top">
                        {Translate("Didn't find your preset?")}
                    </td>
                </tr>
                <tr>
                    <td className="wizardapplicationitem" valign="bottom" align="center">
                        <Button variant="dark" className="wizardapplicationitemblueadd">{Translate("Add custom").toUpperCase() + " 🡢"}</Button>
                    </td>
                </tr>
                </tbody>
                </table> 

                <br clear="all"/>
            </td>
    );
}

IntegrationWizardStepApplicationItem.propTypes = {
  SecurityToken: PropTypes.string,
  Name: PropTypes.string,
  PngUrl: PropTypes.string,
  Index: PropTypes.number,
  OnItemSelected: PropTypes.func
}

export default IntegrationWizardStepApplicationItem