import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceSalesforce = (props) => {

    if (!props.IntegrationSettings.DatasourceSettings.SoapUrl)
    {
        props.IntegrationSettings.DatasourceSettings.SoapUrl = "https://login.salesforce.com/services/Soap/u/29.0"
    }

    const Fields = [
        {Identifier:"UserName", Translation:Translate("Username"), Required:true},
        {Identifier:"Password", Translation:Translate("Password"), Required:true, PasswordField:true},
        {Identifier:"Token", Translation:Translate("Security token"), Required:true},
        {Identifier:"SoapUrl", Translation:"SOAP URL", Required:true},
        {Identifier:"DatasourceVersion", Translation:"", Required:true, StaticValue:"3"}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceSalesforce.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceSalesforce