import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

const DatasourceBaseTextFields = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        props.Fields.filter(field => (field.StaticValue)).map((field) => props.IntegrationSettings.DatasourceSettings[field.Identifier] = field.StaticValue)        
        props.Fields.filter(field => (field.DefaultValue)).map((field) => props.IntegrationSettings.DatasourceSettings[field.Identifier] = field.DefaultValue)

        SetReady()
        SetRepainter(!mRepainter)
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const SetReady = () => 
    {
        let Ready = true
        props.Fields.map((field) => {
            Ready = Ready && ((props.IntegrationSettings.DatasourceSettings[field.Identifier] ? true : false) || !field.Required)
        })

        props.OnSetReady(Ready)
    }
    
    return (
        <div className="scriptitembody">

            {props.Fields.filter(field => (!field.StaticValue)).map((field,index) => 

                <span key={index} hidden={field.StaticField}>
                    <div className="wizardfieldname">{field.Translation.toUpperCase()} {field.Required ? <span className="redtext">*</span> : ""}</div>
                    <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder={field.Hint??""} type={field.PasswordField?"password":"text"} value={props.IntegrationSettings.DatasourceSettings[field.Identifier] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, field.Identifier)} className="wizardinputfield"/></div>
                </span>
            )}

        </div>
    );
}

DatasourceBaseTextFields.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    Fields: PropTypes.array,
    OnSetReady: PropTypes.func
}   

export default DatasourceBaseTextFields