import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceWebCRM = (props) => {

    const Fields = [
        {Identifier:"AuthorizationCode", Translation:Translate("Application token"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceWebCRM.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceWebCRM