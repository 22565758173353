import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceTeamleader = (props) => {

    const AuthUrl = "https://app.teamleader.eu/oauth2/authorize" +
        "?client_id=98a796f8f61dd85230167cc0f2c6e111" +
        "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
        "&response_type=code"
    const TokenUrl = "https://app.teamleader.eu/oauth2/access_token" 
    const TokenBody = "client_id=98a796f8f61dd85230167cc0f2c6e111&client_secret=65c96895e64ae1a1c7c61150336e3452&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)
}

DatasourceTeamleader.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceTeamleader