import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

const DatasourceWorkbooks = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.Api_Key
            && (props.IntegrationSettings.DatasourceSettings.Organisations 
                || props.IntegrationSettings.DatasourceSettings.People))
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("API key").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Api_Key || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Api_Key")} className="wizardinputfield"/></div>
            <br/>
            <input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.Organisations ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "Organisations")}/> {Translate("Include organizations")}<br/>
            <input type="checkbox" checked={props.IntegrationSettings.DatasourceSettings.People ? "checked" : ""} onChange={evt => UpdateDatasourceSetting(evt.target.checked, "People")}/> {Translate("Include people")}<br/>
        </div>
    );
}

DatasourceWorkbooks.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceWorkbooks