import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator.jsx";
import DatasourceBaseTextFields from "./DatasourceBaseTextFields.jsx";

const DatasourceForthLIVE = (props) => {

    const Fields = [
        {Identifier:"ClientID", Translation:Translate("Client ID"), Required:true},
        {Identifier:"ClientSecret", Translation:Translate("Client secret"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceForthLIVE.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceForthLIVE