import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

const DatasourceLightspeed = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        if (!props.IntegrationSettings.DatasourceSettings.Language)
        {
            props.IntegrationSettings.DatasourceSettings.Language = "en"
        }
        
        if (!props.IntegrationSettings.DatasourceSettings.Cluster)
        {
            props.IntegrationSettings.DatasourceSettings.Cluster = "eu"
        }

        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aIdentifier, aValue) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aValue

        SetReady()
    }

    const SetReady = () => 
    {
        const Ready = (props.IntegrationSettings.DatasourceSettings["ApiKey"])
            && (props.IntegrationSettings.DatasourceSettings["ApiSecret"])
            && (props.IntegrationSettings.DatasourceSettings["Language"])
            && (props.IntegrationSettings.DatasourceSettings["Cluster"])


        props.OnSetReady(Ready)
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("API key").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.ApiKey || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ApiKey")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("API secret").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="password" value={props.IntegrationSettings.DatasourceSettings.ApiSecret || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ApiSecret")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Language").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><select name="Language" onChange={evt => UpdateDatasourceSetting("Language", evt.target.value)} className="wizardinputfield">
                <option value="en" selected={props.IntegrationSettings.DatasourceSettings.Language === "en"}>English</option>
                <option value="nl" selected={props.IntegrationSettings.DatasourceSettings.Language === "nl"}>Nederlands</option>
            </select></div>
            <div className="wizardfieldname">{Translate("Cluster").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><select name="Cluster" onChange={evt => UpdateDatasourceSetting("Cluster", evt.target.value)} className="wizardinputfield">
                <option value="eu" selected={props.IntegrationSettings.DatasourceSettings.Cluster === "eu"}>Europe</option>
                <option value="us" selected={props.IntegrationSettings.DatasourceSettings.Cluster === "us"}>United States</option>
            </select></div>
        </div>
    );
}

DatasourceLightspeed.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceLightspeed