import React, {useCallback, useEffect, useState, useRef} from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';
import { Button } from "react-bootstrap";
import { OAuthApiStartPolling, OAuthApiStopPolling } from "../../ExternalApi/OAuthApi"
import { ConfigurationApiForward } from "../../ExternalApi/ConfigurationApi"
import IntegrationWizardDots from "../IntegrationWizard/IntegrationWizardDots"

const DatasourceZoho = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 
    const [mWaitingForState, SetWaitingForState] = useState("")
    const [mError, SetError] = useState("") 
    const [mOAuthWindow, SetOAuthWindow] = useState(null) 

    const cOAuthUrlBase = "https://accounts.zoho.com/oauth/v2/auth" +
        "?scope=ZohoCRM.modules.ALL" +
        "&client_id=1000.T249DIATGXB6MXPYAVO2S2LB3E96KH" +
        "&response_type=code" +
        "&access_type=offline" + 
        "&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2"

    useEffect(() => {
        SetReady()
      }, []); 

    const UpdateDatasourceSetting = (aIdentifier, aValue) =>
    {
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aValue
        SetRepainter(!mRepainter)

        SetReady()
    }

    const SetReady = () => 
    {
        props.OnSetReady(props.IntegrationSettings.DatasourceSettings.Domain && props.IntegrationSettings.DatasourceSettings.RefreshToken)
    }

    async function GetTokens(aAuthorizationCode) 
    {
          let Result = await ConfigurationApiForward(
            props.SecurityToken, 
            "https://accounts.zoho" + props.IntegrationSettings.DatasourceSettings.Domain + "/oauth/v2/token", 
            "POST", 
            "client_id=1000.T249DIATGXB6MXPYAVO2S2LB3E96KH&client_secret=b320c56eae2844ea720e764c26536ecce4bbc8ad6c&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2&grant_type=authorization_code&code=" + aAuthorizationCode, 
            "application/json", 
            "application/x-www-form-urlencoded")        
        if (Result != null)
        {
            UpdateDatasourceSetting("AccessToken", Result.data.access_token)
            UpdateDatasourceSetting("RefreshToken", Result.data.refresh_token)
        }
        else
        {
            SetError("Failed to retrieve tokens")
        }

        SetWaitingForState("")
    }

    const OAuthPollResult = (aResult, aValue, aOAuthWindow) =>
    {
        try 
        { 
            if (aOAuthWindow !== null)
            {
                aOAuthWindow.close() 
                aOAuthWindow = null
                SetOAuthWindow(null)
            }
        } 
        catch {}

        if (aResult)
        {
            console.log("OAuthPollResult success: " + JSON.stringify(aValue))
            if (aValue.Code && aValue.ResponseUrl)
            {
                if (aValue.ResponseUrl.includes("zoho.com.au")) { UpdateDatasourceSetting("Domain",".com.au") }
                else if (aValue.ResponseUrl.includes("zoho.eu")) { UpdateDatasourceSetting("Domain",".eu") }
                else if (aValue.ResponseUrl.includes("zoho.in")) { UpdateDatasourceSetting("Domain",".in") }
                else if (aValue.ResponseUrl.includes("zoho.com.cn")) { UpdateDatasourceSetting("Domain",".com.cn") }
                else if (aValue.ResponseUrl.includes("zoho.jp")) { UpdateDatasourceSetting("Domain",".jp") }
                else { UpdateDatasourceSetting("Domain",".com") }

                GetTokens(aValue.Code)
            }
            else
            {
                SetError("Failed to authenticate. No authorizationcode found")  
            }
        }
        else
        {
            console.log("OAuthPollResult error: " + JSON.stringify(aValue))  
            SetError("Failed to authenticate")          
        }
    }

    const RandomState = () => 
    {
        let Result = "client2020WEB"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

    const StartOAuth = () =>
    {
        const State = RandomState()

        const OAuthUrl = cOAuthUrlBase + "&state=" + State
        const OAuthWindow = window.open(OAuthUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
        SetOAuthWindow(OAuthWindow)

        OAuthApiStartPolling(State, OAuthPollResult, OAuthWindow)

        SetWaitingForState(State)
    }

    const StopOAuth = () =>
    {
        OAuthApiStopPolling()
        try 
        { 
            if (mOAuthWindow !== null)
            {
                mOAuthWindow.close() 
                SetOAuthWindow(null)
            }
        } 
        catch {}

        SetWaitingForState("")
    }

    const Body = props.IntegrationSettings.DatasourceSettings.RefreshToken ?
        //Authorization OK
        <div className="scriptitembody">
            {Translate("$Application connection token received succesfully").replace("$Application","Zoho")}
        </div> :
        (mError ?
            //Authorization failed. Show error and connect button
            <div className="scriptitembody">
                <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button><br/>
                <div>{mError}</div>
            </div> :
            (mWaitingForState ?
                //Waiting for authorization. Show dot counter and cancel button
                <div className="scriptitembody">
                    <Button variant="danger" onClick={() => StopOAuth()} type="submit" className="">{Translate("Cancel")}</Button><br/>
                    <div>{Translate("Waiting for OAUTH verification")}<IntegrationWizardDots/></div>
                </div> :
                //Not authenticating. Show connect button
                <div className="scriptitembody">
                    <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button>
                </div>
            )
        )

    return (
        <span>{Body}</span>
    )
}

DatasourceZoho.propTypes = {
    Domain: PropTypes.string,
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceZoho