import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

const IntegrationWizardStepDatasourceInformation = (props) => {

    useEffect(() => {
        props.IntegrationSettings.DatasourceType = "none"
        props.OnNextButtonEnabled(true)
      }, []);     

    return (
        <IntegrationWizardStepHeader Header={props.Header} SubHeader={props.SubHeader} PdfUrl={"https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".pdf"}/>
    );
}

IntegrationWizardStepDatasourceInformation.propTypes = {
    IntegrationSettings: PropTypes.object,
    Application: PropTypes.string,
    Header: PropTypes.string,  
    SubHeader: PropTypes.string,
    OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepDatasourceInformation