import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Translate } from "./Translator";

import "../css/home.css";

const Loading = () => {
    return (
        <div className="loading-outer">
            <div className="loading-inner">
                <FontAwesomeIcon icon={faSpinner} spin /> {Translate("Loading...")}
            </div>
        </div>
    );
};

export default Loading;