import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceDynamicsEmpireLIVE = (props) => {

    const Fields = [
        {Identifier:"Username", Translation:Translate("Username"), Required:true},
        {Identifier:"Password", Translation:Translate("Password"), Required:true, PasswordField:true},
        {Identifier:"Tenant", Translation:Translate("Tenant"), Required:true},
        {Identifier:"WebServiceUrl", Translation:Translate("Web service URL"), Required:true},
        {Identifier:"ScreenpopDomain", Translation:Translate("Dynamics domain override"), Required:false, Hint:"Leave empty for the default domain"}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)
    
}

DatasourceDynamicsEmpireLIVE.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceDynamicsEmpireLIVE