import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import Button from 'react-bootstrap/Button';

import BackPng from '../../Assets/Back.png';
import NextPng from '../../Assets/Next.png';

const IntegrationWizardNavigation = (props) => {
    return ( 
        <table width="100%" cellPadding="10" cellSpacing="10">
        <tbody>
        <tr>               
            {props.BackVisible ? <td width="150" align="left"><Button variant="outline-dark" block={true} onClick={props.OnBack} type="submit" className="wizardbackbutton"><img src={BackPng} className="wizardbuttonicon" /> &nbsp; {Translate("BACK")}</Button></td> : null}                 
            {props.NextVisible ? <td width="150" align="left"><Button variant="dark" onClick={props.OnNext} type="submit" disabled={!props.NextEnabled} className="wizardnextbutton">{Translate(props.NextCaption)} &nbsp; <img src={NextPng} className="wizardbuttonicon" /></Button></td> : null}
            <td width="*">&nbsp;</td>
        </tr>
        </tbody>
        </table>
        

    ); 
};

IntegrationWizardNavigation.propTypes = {
    BackVisible: PropTypes.bool,
    NextVisible: PropTypes.bool,
    NextEnabled: PropTypes.bool,
    NextCaption: PropTypes.string,
    OnBack: PropTypes.func,
    OnNext: PropTypes.func
  }

export default IntegrationWizardNavigation;