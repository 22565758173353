import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceTribeCRM = (props) => {

    const AuthUrl = "https://auth.tribecrm.nl/oauth2/auth" +
        "?client_id=5b38acdc-472c-4b9c-8efc-7512e5185896" +
        "&redirect_uri=https%3A%2F%2Fcloudcti.nl%2Fwebapi%2Foauth2" +
        "&response_type=code" +
        "&scope=read offline"
    const TokenUrl = "https://auth.tribecrm.nl/oauth2/token" 
    const TokenBody = "client_id=5b38acdc-472c-4b9c-8efc-7512e5185896&client_secret=L4lUmcu8DUcsrlfw65VkiKgF2ywC3H0HvurAoUTr&redirect_uri=https://cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)

}

DatasourceTribeCRM.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceTribeCRM