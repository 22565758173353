import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator"

const IntegrationWizardUnavailableDatasource = (props) => {

    const OpenPdf = () => {
        window.open(props.PdfUrl, "_blank")
    }

    const ManualFieldRequiredApplication = ((props.ApplicationName === "Kinabase") || (props.ApplicationName === "ServiceNow")) 

    return (        
        <div>
            {ManualFieldRequiredApplication ? 
            <div>{Translate("Sorry") + ", " + props.ApplicationName + " " + Translate("cannot be configured in the Webconfigurator because the integration requires manual field configuration")}</div> :
            <div>{Translate("Sorry") + ", " + props.ApplicationName + " " + Translate("cannot be configured in the Webconfigurator because the integration is client-based")}</div>
            }
            <br/>
            {Translate("Please download the Desktop Configurator")} <a href="https://downloadcloudcti.blob.core.windows.net/files/setups/cloudcticonfigurationsetup.exe">{Translate("here")}</a>
        </div>
    );
}

IntegrationWizardUnavailableDatasource.propTypes = {
    ApplicationName: PropTypes.string
}

export default IntegrationWizardUnavailableDatasource