import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceSugarCRMOnline = (props) => {

    const Fields = [
        {Identifier:"UserName", Translation:Translate("Username"), Required:true},
        {Identifier:"PassWord", Translation:Translate("Password"), Required:true, PasswordField:true},
        {Identifier:"DataBase", Translation:Translate("Database"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceSugarCRMOnline.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceSugarCRMOnline