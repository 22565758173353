import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

const IntegrationWizardStepReviewScript = (props) => {

    return (
        <li>
            {props.Script.Name + ": "} 
            {(props.Script.ScriptType === "CSD" ? Translate("predefined script") : 
                (props.Script.ScriptType === "ServerCSD" ? Translate("predefined script") : 
                    (props.Script.ScriptType === "OpenWebPage" ? "" : Translate("unknown type"))))}
            <u>{props.Script.ScriptType === "OpenWebPage" ? props.Script.WebPage : ""}</u>
        </li>
    );
}

IntegrationWizardStepReviewScript.propTypes = {
  Script: PropTypes.object
}

export default IntegrationWizardStepReviewScript