import React, {useCallback, useEffect, useState, useRef} from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';
import { Button } from "react-bootstrap";
import { OAuthApiStartPolling, OAuthApiStopPolling } from "../../ExternalApi/OAuthApi"
import { ConfigurationApiForward } from "../../ExternalApi/ConfigurationApi"
import IntegrationWizardDots from "../IntegrationWizard/IntegrationWizardDots"

const DatasourceMicrosoftDynamicsOnline = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 
    const [mWaitingForState, SetWaitingForState] = useState("")
    const [mError, SetError] = useState("") 
    const [mOAuthWindow, SetOAuthWindow] = useState(null) 

    const cClientId = "d2b5e92c-dadf-4d33-9dab-b6b5517f8773"
    const cClientSecret = "q=d_=950Fxzn3seCwrs/d.8NeF:5cDOS"
    const cRedirectUrl = "https://auth.cloudcti.nl/webapi/oauth2"


    useEffect(() => {

        if ((props.IntegrationSettings.DatasourceSettings["ServiceUrl"] === "")
            || (props.IntegrationSettings.DatasourceSettings["ServiceUrl"] === null)
            || (props.IntegrationSettings.DatasourceSettings["ServiceUrl"] === undefined))
        {
            props.IntegrationSettings.DatasourceSettings["ServiceUrl"] = "https://[domain].api.crm4.dynamics.com/"
        }

        SetReady()
        SetRepainter(!mRepainter)
      }, []); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue
        SetRepainter(!mRepainter)

        SetReady()
    }

    const SetReady = () => 
    {
        props.OnSetReady(props.IntegrationSettings.DatasourceSettings.ServiceUrl && props.IntegrationSettings.DatasourceSettings.RefreshToken)
    }

    async function GetTokens(aAuthorizationCode) 
    {
        let Result = await ConfigurationApiForward(
            props.SecurityToken, 
            "https://login.microsoftonline.com/common/oauth2/v2.0/token", 
            "POST", 
            "client_id=" + cClientId + "&client_secret=" + cClientSecret + "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2&grant_type=authorization_code&code=" + aAuthorizationCode, 
            "application/json", 
            "application/x-www-form-urlencoded")
        if (Result != null)
        {
            UpdateDatasourceSetting(Result.data.access_token, "AccessToken")
            UpdateDatasourceSetting(Result.data.refresh_token, "RefreshToken")
        }
        else
        {
            SetError("Failed to retrieve tokens")
        }

        SetWaitingForState("")
    }

    const OAuthPollResult = (aResult, aValue, aOAuthWindow) =>
    {
        try 
        { 
            if (aOAuthWindow !== null)
            {
                aOAuthWindow.close() 
                aOAuthWindow = null
                SetOAuthWindow(null)
            }
        } 
        catch {}

        if (aResult)
        {
            console.log("OAuthPollResult success: " + JSON.stringify(aValue))
            if (aValue.Code && aValue.ResponseUrl)
            {
                GetTokens(aValue.Code)
            }
            else
            {
                SetError("Failed to authenticate. No authorizationcode found")  
            }
        }
        else
        {
            console.log("OAuthPollResult error: " + JSON.stringify(aValue))  
            SetError("Failed to authenticate")          
        }
    }

    const RandomState = () => 
    {
        let Result = "client2020WEB"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

    const ServiceURL = () => 
    {
        let Url = props.IntegrationSettings.DatasourceSettings["ServiceUrl"]
        //tbd: format url?
        return Url
    }

    const StartOAuth = () =>
    {
        const State = RandomState()

        const OAuthUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
            "?client_id=" + cClientId +
            "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
            "&scope=offline_access+" + ServiceURL() + "/user_impersonation" +
            "&response_type=code" +
            "&response_mode=query" +
            "&prompt=login" +
            "&state=" + State
        const OAuthWindow = window.open(OAuthUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
        SetOAuthWindow(OAuthWindow)
        OAuthApiStartPolling(State, OAuthPollResult, OAuthWindow)

        SetWaitingForState(State)
    }

    const StopOAuth = () =>
    {
        OAuthApiStopPolling()
        try 
        { 
            if (mOAuthWindow !== null)
            {
                mOAuthWindow.close() 
                SetOAuthWindow(null)
            }
        } 
        catch {}        

        SetWaitingForState("")
    }

    const OAuthBody = props.IntegrationSettings.DatasourceSettings.RefreshToken ?
        //Authorization OK
        <div className="scriptitembody">
            {Translate("$Application connection token received succesfully").replace("$Application","Microsoft Dynamics Online")}
        </div> :
        (mError ?
            //Authorization failed. Show error and connect button
            <div className="scriptitembody">
                <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button><br/>
                <div>{mError}</div>
            </div> :
            (mWaitingForState ?
                //Waiting for authorization. Show dot counter and cancel button
                <div className="scriptitembody">
                    <Button variant="danger" onClick={() => StopOAuth()} type="submit" className="">{Translate("Cancel")}</Button><br/><br/>
                    <div>{Translate("Waiting for OAUTH verification")}<IntegrationWizardDots/></div>
                </div> :
                (props.IntegrationSettings.DatasourceSettings.ServiceUrl ? 
                    //There is a service url
                    <div className="scriptitembody">
                        <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button>
                    </div> :
                    //There is no service url
                    <div className="scriptitembody">
                        <Button variant="primary" disabled={true} type="submit" className="">{Translate("Connect")}</Button>
                    </div>)
            )
        )

    return (
        <span>
            <div className="wizardfieldname">{Translate("Service URL").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="https://[domain].api.crm4.dynamics.com/" type="text" size="100" value={props.IntegrationSettings.DatasourceSettings["ServiceUrl"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ServiceUrl")} className="wizardinputfield"/></div>
            {OAuthBody}
        </span>
    )
}

DatasourceMicrosoftDynamicsOnline.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceMicrosoftDynamicsOnline