import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import IntegrationWizard from "./IntegrationWizard";
import { ConfigurationApiCheckToken, ConfigurationApiCRMScriptsGet, ConfigurationApiIntegrationGetSingle } from "../ExternalApi/ConfigurationApi";
import { SetLanguageId, Translate } from "./Translator";
import "../css/home.css";

const IntegrationWizardWrapper = (props) => {

    //This wrapper performs a few actions before starting the wizard
    //These actions only have to be performed once

    //Get the parameters from the URL
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const ciParams = new URLSearchParams();
    for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
    const SecurityToken = ciParams.get('securitytoken')
    const Action = ciParams.get('action')?.toLowerCase()
    const IntegrationGuid = ciParams.get('integrationguid')
    const After = ciParams.get('after') ?? ""
    const LanguageId = ciParams.get('languageid') ?? ""

    const [mSecurityTokenOK, SetSecurityTokenOK] = useState(-1);
    const [mPredefinedScripts, SetPredefinedScripts] = useState([]);
    const [mStartingIntegrationSettings, SetStartingIntegrationSettings] = useState(null);    

    useEffect(() => {

      async function GetSecurityTokenOK() {
          const Result = await ConfigurationApiCheckToken(SecurityToken)
          return Result ? 1 : 0
      }

      async function GetPredefinedScripts() {
          const Result = await ConfigurationApiCRMScriptsGet(SecurityToken)
          return Result ?? []
      }

      async function Initialize() {  
          SetLanguageId(LanguageId)          
          SetSecurityTokenOK (await GetSecurityTokenOK(SecurityToken))
          SetPredefinedScripts(await GetPredefinedScripts(SecurityToken))
          if ((Action === "update") || (Action === "setcountrycode") || (Action === "setnotification") || (Action === "setscripts"))
          {
            const IntegrationSettings = await ConfigurationApiIntegrationGetSingle(SecurityToken, IntegrationGuid)
            SetStartingIntegrationSettings(IntegrationSettings)
          }
          else
          {
            SetStartingIntegrationSettings({
              IntegrationGuid: "",
              ApplicationName: "",
              NumberSettings: {CountryCode:""},
              NotificationTemplate: {Header:Translate("Incoming call"), Body:["#(Caller number)","%(Name)","%(Organization)"]},
              DatasourceType: "none",
              DatasourceFields: [],
              DatasourceSettings: {},
              Scripts: [],
              WizardSettings: {Application: ""},
              AutoUpdateFlags: "day",
              Enabled: true,
              CloudSync: true
            })
          }
        }

        Initialize()
    }, []);

    return mSecurityTokenOK === 1 ?
        (mStartingIntegrationSettings !== null) ?
            <IntegrationWizard SecurityToken={SecurityToken} Action={Action} IntegrationGuid={IntegrationGuid} After={After} IntegrationSettings={mStartingIntegrationSettings} PredefinedScripts={mPredefinedScripts} /> :
            <Loading /> :
        (mSecurityTokenOK === 0 ? 
            <div>Invalid security token</div> : 
            <Loading />)
};

export default IntegrationWizardWrapper;