import React from "react";
import PropTypes from 'prop-types';
import ArrowPng from '../../Assets/Arrow.png';
import { GetLanguageCode } from "../Translator";

const IntegrationWizardStepApplicationDatasourceItem = (props) => {

    const Description = () => {
        try
        {
            const LanguageCode = GetLanguageCode() ?? "default"         
            const Description = JSON.parse(props.Datasource.Description)
            if (Description)
            {
                return Description[LanguageCode]
            }
        }
        catch {}
        
        return "";
    }

    const OnClick = () => {
        props.OnItemSelected(props.Index)
    }

    return (  
        <span>    
            <table width="100%" cellPadding="30" cellSpacing="0">
            <tbody>
            <tr>
                <td className="wizardcommandlink" onClick={OnClick}>

                    <table>
                    <tbody>
                    <tr>
                        <td width="25"><img src={ArrowPng} alt="pdf" width="13" height="13"/></td>
                        <td width="*" className="wizardcommandlinkheader">{props.Datasource.Id}</td>
                    </tr>
                    <tr>
                        <td width="25"></td>
                        <td width="*" className="wizardcommandlinksubheader">{Description()}</td>
                    </tr>
                    </tbody>
                    </table> 

                </td>
            </tr>
            </tbody>
            </table>
            <br clear="all"/> 
        </span>
    );
}

IntegrationWizardStepApplicationDatasourceItem.propTypes = {
  Datasource: PropTypes.object,
  Index: PropTypes.number,
  OnItemSelected: PropTypes.func
}

export default IntegrationWizardStepApplicationDatasourceItem