export function TranslationsNederlands() 
{
    return [    
        {"id":"Actions","value":"Acties"},
        {"id":"Access key","value":"Toegang sleutel"},
        {"id":"Access token","value":"Toegang token"},
        {"id":"Account","value":"Account"},
        {"id":"Account ID","value":"Account ID"},
        {"id":"Account URL","value":"Account URL"},
        {"id":"Actions on incoming call","value":"Acties bij een inkomend gesprek"},
        {"id":"Add a custom integration","value":"Een handmatige integratie toevoegen"},
        {"id":"Add new integration","value":"Integratie toevoegen"},
        {"id":"Add new script","value":"Script toevoegen"},
        {"id":"API private key","value":"API prive sleutel"},
        {"id":"API public key","value":"API publieke sleutel"},
        {"id":"API key","value":"API sleutel"},
        {"id":"API secret","value":"API geheim"},
        {"id":"API URL","value":"API URL"},
        {"id":"App token","value":"App token"},
        {"id":"Application","value":"Applicatie"},
        {"id":"$Application connection token received succesfully","value":"$Application verbindingstoken is correct ontvangen"}, 
        {"id":"Application token","value":"Applicatie token"},       
        {"id":"Auto-execute","value":"Automatisch uitvoeren"}, 
        {"id":"Automatic synchronization disabled","value":"Automatische synchronisatie uitgeschakeld"},
        {"id":"Automatic synchronization in","value":"Automatische synchronisatie over"},
        {"id":"Automatically synchronize every day","value":"Automatisch elke dag synchroniseren"},
        {"id":"Automatically synchronize every week","value":"Automatisch elke week synchroniseren"},
        {"id":"BACK","value":"VORIGE"},
        {"id":"Call notification","value":"Gesprek notificatie"},
        {"id":"Caller name","value":"Beller naam"},
        {"id":"Caller number","value":"Beller nummer"},
        {"id":"Cancel","value":"Annuleren"},
        {"id":"cannot be configured in the Webconfigurator because the integration is client-based","value":"kan niet worden geconfigureerd in de Webconfigurator omdat de integratie client-based is"},
        {"id":"cannot be configured in the Webconfigurator because the integration requires manual field configuration","value":"kan niet worden geconfigureerd in de Webconfigurator omdat de integratie handmatige veld configuratie vereist"},
        {"id":"Check settings","value":"Instellingen controleren"},
        {"id":"Checking CRM settings","value":"Bezig met controleren van de CRM instellingen"},        
        {"id":"Choose an application preset","value":"Kies een applicatie preset"},
        {"id":"Choose how you want to link to this application's recognition","value":"Kies hoe u wilt koppelen met de herkenning van deze applicatie"},
        {"id":"Choose preset","value":"Kies uw preset"},
        {"id":"Client call notification","value":"Client gesprek notificatie"},
        {"id":"Client ID","value":"Client ID"},
        {"id":"Client secret","value":"Client geheim"},
        {"id":"Cluster","value":"Cluster"},
        {"id":"Company Id","value":"Bedrijf Id"},
        {"id":"Configure","value":"Configureren"},
        {"id":"Configure incoming call","value":"Inkomend gesprek configureren"},        
        {"id":"Configure the $DatasourceName connection","value":"Configureer de $DatasourceName verbinding"}, 
        {"id":"Configure the action do you want to perform","value":"Configureer de actie die u wilt uitvoeren"}, 
        {"id":"Configure the information you want the client to show when a caller is recognized from this set","value":"Configureer de informatie die de client moet laten zien wanneer een gesprek wordt herkend uit deze set"}, 
        {"id":"Configure the recognition fields","value":"Configureer de herkenningsvelden"},   
        {"id":"Connect","value":"Verbinden"},    
        {"id":"Country code","value":"Landcode"},           
        {"id":"CRM configuration","value":"CRM configuratie"},
        {"id":"Current set","value":"Huidige set"},
        {"id":"Custom phone fields","value":"Handmatige telefoon velden"},        
        {"id":"Database","value":"Database"},
        {"id":"Database country code","value":"Database landcode"},
        {"id":"Database ID","value":"Database ID"},
        {"id":"days","value":"dagen"},
        {"id":"days ago","value":"dagen geleden"},
        {"id":"Ddi name","value":"Ddi naam"},
        {"id":"Ddi number","value":"Ddi nummer"},
        {"id":"Delete","value":"Verwijderen"},
        {"id":"Device name","value":"Toestel naam"},
        {"id":"Device number","value":"Toestel nummer"},
        {"id":"Disable","value":"Uitschakelen"},
        {"id":"Domain","value":"Domein"},
        {"id":"Done","value":"Klaar"},
        {"id":"Enable","value":"Inschakelen"},   
        {"id":"<empty line>","value":"<lege regel>"},       
        {"id":"Enter the import country code","value":"Voer de landcode van de import in"},
        {"id":"Execute script","value":"Voer script uit"},
        {"id":"Failed to update the integration","value":"Het updaten van de integratie is mislukt"},
        {"id":"Finish","value":"Voltooien"},
        {"id":"here","value":"hier"},
        {"id":"hours","value":"uur"},
        {"id":"hours ago","value":"uur geleden"},
        {"id":"Include accounts","value":"Accounts toevoegen"},  
        {"id":"Include contacts","value":"Contacten toevoegen"},  
        {"id":"Include employees","value":"Werknemers toevoegen"},  
        {"id":"Include companies","value":"Bedrijven toevoegen"},  
        {"id":"Include leads","value":"Leads toevoegen"}, 
        {"id":"Include organizations","value":"Organisaties toevoegen"},  
        {"id":"Include people","value":"Mensen toevoegen"},  
        {"id":"Include users","value":"Gebruikers toevoegen"},  
        {"id":"InSite URL","value":"InSite URL"},              
        {"id":"Integrations","value":"Integraties"},            
        {"id":"just now","value":"zojuist"},             
        {"id":"Key","value":"Sleutel"}, 
        {"id":"Language","value":"Taal"},
        {"id":"Loading...","value":"Bezig met laden..."},
        {"id":"Location info","value":"Locatie informatie"},
        {"id":"minute","value":"minuut"},
        {"id":"minute ago","value":"minuut geleden"},
        {"id":"minutes","value":"minuten"},
        {"id":"minutes ago","value":"minuten geleden"},
        {"id":"My Custom Script","value":"Mijn Aangepaste Script"},
        {"id":"Next","value":"Volgende"},
        {"id":"Next synchronization could not be determined","value":"Volgende synchronisatie kan niet worden bepaald"},        
        {"id":"No recognition has been configured","value":"Er is geen herkenning geconfigureerd"},      
        {"id":"No settings are required. Click Next to continue","value":"Er zijn geen instellingen nodig. Klik Volgende om door te gaan"},
        {"id":"Not synchronized yet","value":"Nog niet gesynchoniseerd"}, 
        {"id":"OK","value":"OK"}, 
        {"id":"Only synchronize manually","value":"Alleen handmatig synchroniseren"},         
        {"id":"Open applicatie","value":"Open applicatie"},
        {"id":"Open webpage","value":"Open webpagina"},
        {"id":"Or, if your application is not in the list","value":"Of, als uw applicatie niet in de lijst staat"},
        {"id":"Organization ID","value":"Organisatie ID"},
        {"id":"Organization number","value":"Organisatienummer"},
        {"id":"Participant number","value":"Deelnemer nummer"},
        {"id":"Password","value":"Wachtwoord"},
        {"id":"Perform an action in application","value":"Voer een actie uit in applicatie"},
        {"id":"phone number records","value":"telefoonnummer records"},
        {"id":"Please download the Desktop Configurator","value":"Download de Desktop Configurator"},
        {"id":"predefined script","value":"voorgedefinieerd script"},
        {"id":"Preview","value":"Voorbeeld"},
        {"id":"Preview incoming call","value":"Voorbeeld inkomend gesprek"},
        {"id":"Profit Services URL","value":"Profit Services URL"},
        {"id":"Recognition","value":"Herkenning"},
        {"id":"Recognition from","value":"Herkenning uit"},
        {"id":"Reference","value":"Referentie"},
        {"id":"Region","value":"Regio"},
        {"id":"Requests per minute","value":"Verzoeken per minuut"},        
        {"id":"Review your integration","value":"Overzicht van uw integratie"}, 
        {"id":"FINISH","value":"VOLTOOIEN"},  
        {"id":"NEXT","value":"VOLGENDE"},          
        {"id":"Sandbox account","value":"Sandbox account"}, 
        {"id":"Security token","value":"Beveiligingstoken"}, 
        {"id":"Screenpop URL","value":"Screenpop URL"},    
        {"id":"Script executed ok","value":"Script is correct uitgevoerd"},
        {"id":"Script name","value":"Scriptnaam"},
        {"id":"Script settings","value":"Script instellingen"},
        {"id":"Scripts","value":"Scripts"},
        {"id":"Secret","value":"Geheim"},
        {"id":"Secret key","value":"Geheime sleutel"},
        {"id":"Select how the data should be synchronized","value":"Selecteer hoe de data gesynchroniseerd moet worden"},
        {"id":"Select your FileVine login","value":"Selecteer uw FileVine login"},        
        {"id":"Service URL","value":"Service URL"},        
        {"id":"Settings OK","value":"Instellingen zijn correct"},
        {"id":"Settings OK. Found $TotalCount items","value":"Instellingen zijn correct. $TotalCount items gevonden"},
        {"id":"Signed in as","value":"Aangemeld als"},
        {"id":"Site","value":"Site"},
        {"id":"Sorry","value":"Sorry"},
        {"id":"Source info","value":"Bron informatie"},
        {"id":"Subdomain","value":"Subdomein"},
        {"id":"Synchronization info","value":"Synchronisatie informatie"},
        {"id":"Sync now","value":"Sync nu"}, 
        {"id":"System name","value":"Systeemnaam"},     
        {"id":"Tenant","value":"Tenant"},
        {"id":"Test script","value":"Test script"},
        {"id":"This application uses live search and does not need synchronization","value":"Deze applicatie gebruikt live search and heeft geen synchronisatie nodig"},
        {"id":"The server will automatically execute script","value":"De server voert automatisch het script uit"},
        {"id":"This integration is disabled","value":"Deze integratie is uitgeschakeld"},
        {"id":"This recognition is disabled","value":"Deze herkenning is uitgeschakeld"},
        {"id":"This script is disabled","value":"Dit script is uitgeschakeld"},
        {"id":"Token","value":"Token"},
        {"id":"Token XML","value":"Token XML"},
        {"id":"Unavailable in the Webconfigurator","value":"Niet beschikbaar in de Webconfigurator"},
        {"id":"unknown type","value":"onbekend type"},
        {"id":"URL","value":"URL"},
        {"id":"Use a team domain","value":"Gebruik een team domein"},
        {"id":"User","value":"Gebruiker"},
        {"id":"Username","value":"Gebruikersnaam"},
        {"id":"Waiting for OAUTH verification","value":"Bezig met wachten op OAUTH verificatie"},
        {"id":"Webpage","value":"Webpagina"},
        {"id":"Web service username","value":"Web service gebruikersnaam"},
        {"id":"Web service password","value":"Web service wachtwoord"},   
        {"id":"WebService URL","value":"WebService URL"},   
        {"id":"Welcome to the <branding_product> Webconfigurator for CRM integration","value":"Welkom bij de <branding_product> Webconfigurator voor CRM integratie"}, 
        {"id":"with caption","value":"met titel"},
        {"id":"With this tool you can manage your CRM integrations","value":"Met deze tool kunt u uw CRM integraties beheren"},   
        {"id":"Your organization","value":"Uw organisatie"}        
    ]
}