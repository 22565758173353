import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import PdfPng from '../../Assets/Pdf.png';

//Not a standard datasource because CustomSite visibility has to be toggled

const DatasourceAct = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        if (!props.IntegrationSettings.DatasourceSettings.Region)
        {
            props.IntegrationSettings.DatasourceSettings.Region = "us"
        }
        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        if (aIdentifier === "Username")
        {
            props.IntegrationSettings.DatasourceSettings.Username = aNewValue
        }
        else if (aIdentifier === "Password")
        {
            props.IntegrationSettings.DatasourceSettings.Password = aNewValue
        }
        else if (aIdentifier === "Database")
        {
            props.IntegrationSettings.DatasourceSettings.Database = aNewValue
        }
        else if (aIdentifier === "Region")
        {
            props.IntegrationSettings.DatasourceSettings.Region = aNewValue
        }
        else if (aIdentifier === "CustomSite")
        {
            props.IntegrationSettings.DatasourceSettings.CustomSite = aNewValue
        }

        SetReady()
    }

    const SetReady = () => 
    {
        if (props.IntegrationSettings.DatasourceSettings.Username 
            && props.IntegrationSettings.DatasourceSettings.Password
            && props.IntegrationSettings.DatasourceSettings.Database
            && props.IntegrationSettings.DatasourceSettings.Region
            && (props.IntegrationSettings.DatasourceSettings.Region !== "custom" || props.IntegrationSettings.DatasourceSettings.CustomSite))
        {
            props.OnSetReady(true)
        }
        else
        {
            props.OnSetReady(false)
        }
    }
    
    const CustomSiteName = props.IntegrationSettings.DatasourceSettings.Region === "custom" ? <div className="wizardfieldname">{Translate("Site").toUpperCase()} <span className="redtext">*</span></div> : <span/>
    const CustomSiteValue = props.IntegrationSettings.DatasourceSettings.Region === "custom" ? <div className="wizardfieldvalue"><input autoComplete="new-password" type="password" value={props.IntegrationSettings.DatasourceSettings.CustomSite || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "CustomSite")} className="wizardinputfield"/></div> : <span/>

    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Username").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Username || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Username")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Password").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="password" value={props.IntegrationSettings.DatasourceSettings.Password || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Password")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Database").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Database || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Database")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Region").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue">
                <select name="Region" onChange={evt => UpdateDatasourceSetting(evt.target.value, "Region")} className="wizardinputfield">
                    <option value="us" selected={props.IntegrationSettings.DatasourceSettings.Region === "us"}>United States (https://apius.act.com)</option>
                    <option value="uk" selected={props.IntegrationSettings.DatasourceSettings.Region === "uk"}>United Kingdom (https://apiuk.act.com)</option>
                    <option value="au" selected={props.IntegrationSettings.DatasourceSettings.Region === "au"}>Australia (https://apiau.act.com)</option>
                    <option value="eu" selected={props.IntegrationSettings.DatasourceSettings.Region === "eu"}>Europe (https://apieu.act.com)</option>
                    <option value="custom" selected={props.IntegrationSettings.DatasourceSettings.Region === "custom"}>Custom (https://usp1-iis-99.hosted1.act.com/{"{Site}"})</option>
                </select>    
            </div> 
            {CustomSiteName}  
            {CustomSiteValue}  
        </div>
    );
}

DatasourceAct.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceAct