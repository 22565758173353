import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

import { GetDatasources } from "../Datasources/Datasources.jsx";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

const IntegrationWizardStepDatasourceType = (props) => {

    const RenderDatasourceTypesInRows = () => {
        
        const MaxCols = Math.trunc(props.StepWidth / 250)

        const items = [...GetDatasources()];
        const rows = [];        
      
        while (items.length) {
            rows.push(items.splice(0, MaxCols));
        }
      
        return rows.map((row,rindex) => (
          <tr key={"datasourcetyperow" + rindex}>{row.map((datasource,index) => <td width="250" key={"datasourcetype" + index} onClick={() => props.OnDatasourceTypeSelected(datasource.id)}><span className="linkitem">{datasource.name}</span></td>)}</tr>
        ));
    }

    return (        
        <div>
            <IntegrationWizardStepHeader Header={Translate("Choose how you want to link to this application's recognition")}/>

            <table cellPadding="3" cellSpacing="3">
            <tbody>
                {RenderDatasourceTypesInRows()}
            </tbody>
            </table>
        </div>
    )
}

IntegrationWizardStepDatasourceType.propTypes = {
  IntegrationSettings: PropTypes.object,
  StepWidth: PropTypes.number,
  OnDatasourceTypeSelected: PropTypes.func
}

export default IntegrationWizardStepDatasourceType