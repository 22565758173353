import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceGripp = (props) => {

    const Fields = [
        {Identifier:"ApiToken", Translation:Translate("API token"), Required:true},
        {Identifier:"Domain", Translation:Translate("Domain"), Required:true, Hint:"<" + Translate("Domain").toLowerCase() + ">.gripp.com", DefaultValue:"<" + Translate("Domain").toLowerCase() + ">.gripp.com"}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceGripp.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceGripp