import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceNanny4All = (props) => {

    const Fields = [
        {Identifier:"Username", Translation:Translate("Username"), Required:true},
        {Identifier:"Password", Translation:Translate("Password"), Required:true, PasswordField:true},
        {Identifier:"Site", Translation:Translate("Site"), Required:true, Hint:"<site>.nanny4all.nl", DefaultValue:"<site>.nanny4all.nl"}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceNanny4All.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceNanny4All