import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceActiveCampaign = (props) => {

    const Fields = [
        {Identifier:"AccountUrl", Translation:Translate("Account URL"), Required:true},
        {Identifier:"ApiUrl", Translation:Translate("API URL"), Required:true},
        {Identifier:"ApiKey", Translation:Translate("API key"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceActiveCampaign.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceActiveCampaign