import React, {useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

//LEGACY - NOT USED ANYMORE?

const IntegrationWizardStepSynchronizationSettings = (props) => {

    const [mRepaint, SetRepaint] = useState(false); //used only as a repainter

    const Repaint = () => {
        SetRepaint(!mRepaint)
    }

    const UpdateSynchronizationSettings = (newValue) =>
    {
        props.IntegrationSettings.AutoUpdateFlags = newValue
        Repaint()
    }    

    return (
        <div>
            <IntegrationWizardStepHeader Header={Translate("Select how the data should be synchronized")}/>
            
            <table width="100%" cellPadding="50" cellSpacing="0">
            <tbody>
            <tr>
                <td className="wizardoutercell" style={{ backgroundColor: BrandingColorLight}}>
                    <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "day"} value="day" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> Automatically synchronize every day<br/>
                    <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "week"} value="week" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> Automatically synchronize every week<br/>
                    <input type="radio" checked={props.IntegrationSettings.AutoUpdateFlags === "none"} value="none" onChange={evt => UpdateSynchronizationSettings(evt.target.value)}/> Only synchronize manually
                </td>
            </tr>
            </tbody>
            </table> 
        </div>
    )
}

IntegrationWizardStepSynchronizationSettings.propTypes = {
    IntegrationSettings: PropTypes.object
}

export default IntegrationWizardStepSynchronizationSettings