import React, {useState} from "react";
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import { Translate } from "../Translator";

const IntegrationWizardStepScriptItemWebPage = (props) => {

    const [mWebPage, SetWebPage] = useState(props.Script.WebPage); //used only as a repainter

    const UpdateWebPage = (newValue) =>
    {
        props.Script.WebPage = newValue
        SetWebPage(newValue)
    }

    return (
        <span>
            <div className="wizardfieldname">{Translate("Webpage").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.Script.WebPage} onChange={evt => UpdateWebPage(evt.target.value)} className="wizardinputfield"/></div> 

            <Popup trigger={<Button variant="primary" id="recognitionpopup">+ Add variable</Button>} arrow={false} position="bottom left" closeOnDocumentClick={true}>
                {props.DatasourceFields ? <div className="wizardsteppopupitemheader">-- Recognition fields --</div> : <span/>}
                {props.DatasourceFields ? props.DatasourceFields.map((field,findex) => 
                    <div key={findex} onClick={() => { UpdateWebPage(props.Script.WebPage + "$(" + field.FieldLabel + ")"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"$(" + field.FieldLabel + ")"}</div>) 
                    : <span/>
                }
                <div className="wizardsteppopupitemheader"><br/>-- Call fields --</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Caller number)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Caller number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Caller name)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Caller name)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Device number)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Device number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Device name)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Device name)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Ddi number)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Ddi number)"}</div>
                <div onClick={() => { UpdateWebPage(props.Script.WebPage + "#(Ddi name)"); document.getElementById("recognitionpopup").click()}} className="wizardsteppopupitem">{"#(Ddi name)"}</div>
            </Popup>
            
            <br/>
        </span>
    );
}

IntegrationWizardStepScriptItemWebPage.propTypes = {
  DatasourceFields: PropTypes.array,
  Script: PropTypes.object,
  PredefinedScript: PropTypes.object
}

export default IntegrationWizardStepScriptItemWebPage