import React from "react";
import PropTypes from 'prop-types';
import PdfPng from '../../Assets/Pdf.png';
import { Translate } from "../Translator";

import IntegrationWizardStepApplicationDatasourceItem from "./IntegrationWizardStepApplicationDatasourceItem.jsx"
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

const IntegrationWizardStepApplicationDatasource = (props) => {

    return (        
        <div>
            <IntegrationWizardStepHeader Header={Translate("Choose how you want to link to this application's recognition")} PdfUrl={"https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".pdf"}/>

            {props.ApplicationDatasources.map((applicationDatasource,index) => (<IntegrationWizardStepApplicationDatasourceItem key={"datasource" + index} Datasource={applicationDatasource} Index={index} OnItemSelected={props.OnApplicationDatasourceSelected}/>))}
        </div>
    );

}

IntegrationWizardStepApplicationDatasource.propTypes = {
  Application: PropTypes.string,
  ApplicationDatasources: PropTypes.array,
  OnApplicationDatasourceIndexSelected: PropTypes.func
}

export default IntegrationWizardStepApplicationDatasource