import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import IntegrationWizardStepHeader from "./IntegrationWizardStepHeader.jsx";

//LEGACY - NOT USED ANYMORE?

const IntegrationWizardStepCountryCode = (props) => {

    const [mRepaint, SetRepaint] = useState(false); //used only as a repainter

    useEffect(() => {
        props.OnNextButtonEnabled(props.IntegrationSettings.NumberSettings.CountryCode !== "")
      }, []); 

    const Repaint = () => {
        SetRepaint(!mRepaint)
    }

    const UpdateCountryCode = (newValue) =>
    {
        const CountryCode = newValue.trim()
        props.IntegrationSettings.NumberSettings.CountryCode = CountryCode
        Repaint()
        props.OnNextButtonEnabled(CountryCode !== "")
    }    

    return (
        <div>
            <IntegrationWizardStepHeader Header={Translate("Enter the import country code")}/>
            
            <table width="100%" cellPadding="50" cellSpacing="0">
            <tbody>
            <tr>
                <td className="wizardoutercell">
                    <div className="wizardfieldname">{Translate("Country code").toUpperCase()} <span className="redtext">*</span></div>
                    <div className="wizardfieldvalue"><input placeholder="" type="text" value={props.IntegrationSettings.NumberSettings.CountryCode || ""} onChange={evt => UpdateCountryCode(evt.target.value)} className="wizardinputfieldcountrycode" size="10"/><span className="wizardcountrycodeplus">+</span></div>
                </td>
            </tr>
            </tbody>
            </table>  
        </div>
    )
}

IntegrationWizardStepCountryCode.propTypes = {
    IntegrationSettings: PropTypes.object,
    OnNextButtonEnabled: PropTypes.func
}

export default IntegrationWizardStepCountryCode