import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceOutreach = (props) => {

    const AuthUrl = "https://api.outreach.io/oauth/authorize" +
        "?scope=prospects.read" +
        "&client_id=cIQJjbi3Jp_CtMdO6uJUBLh7avWttn_LVxDsB5DLiWc" +
        "&response_type=code" +
        "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2"
    const TokenUrl = "https://api.outreach.io/oauth/token" 
    const TokenBody = "client_id=cIQJjbi3Jp_CtMdO6uJUBLh7avWttn_LVxDsB5DLiWc&client_secret=TISyacYvepjaPdIwyniKTyWLYqHDpvYyMDsLDqLUYTY&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)

}

DatasourceOutreach.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceOutreach