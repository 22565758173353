import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceInfusionSoft = (props) => {

    const AuthUrl = "https://accounts.infusionsoft.com/app/oauth/authorize" +
        "?client_id=mTaLSXxAV57KWfqC2EjW4t5Ojms2caLF" + 
        "&redirect_uri=https://www.cloudcti.nl/webapi/oauth2" + 
        "&scope=full" + 
        "&response_type=code"
    const TokenUrl = "https://api.infusionsoft.com/token" 
    const TokenBody = "client_id=mTaLSXxAV57KWfqC2EjW4t5Ojms2caLF&client_secret=d5FolwakzUz682iB&redirect_uri=https://www.cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code="
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)

}

DatasourceInfusionSoft.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceInfusionSoft