import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceFysioManager = (props) => {

    const Fields = [
        {Identifier:"Username", Translation:Translate("Client ID"), Required:true},
        {Identifier:"Password", Translation:Translate("Client secret"), Required:true},
        {Identifier:"Domain", Translation:Translate("Domain"), Hint:"https://<domain>.fysiomanager.nl", DefaultValue:"https://<domain>.fysiomanager.nl", Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceFysioManager.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceFysioManager