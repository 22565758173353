import React, {useCallback, useEffect, useState, useRef} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";
import { ConfigurationApiDatasourceInfo } from "../../ExternalApi/ConfigurationApi"


const DatasourceIntercom = (props) => {

    const [mServers, SetServers] = useState([])  
    const [mSelectedServer, SetSelectedServer] = useState("")  

    useEffect(() => {

        async function GetServers() {
            try 
            {
                const result = await ConfigurationApiDatasourceInfo(props.SecurityToken, props.IntegrationSettings.DatasourceType, "Servers", props.IntegrationSettings.DatasourceSettings)
                if (result.status === 200)
                {
                    return result.data.Info
                }        
            } 
            catch (ex) 
            {    
                console.log(ex)
                return false
            }
        }
  
        async function Initialize() {  
            const Servers = await GetServers()

            SetServers(Servers)  
            if (Servers.length > 0)
            {
                SetSelectedServerEx(Servers[1].BaseUrlPrefix)
            }
        }
  
        Initialize()
    }, []);

    function SetSelectedServerEx(aSelectedServer)
    {
        props.IntegrationSettings.DatasourceSettings.BaseUrlPrefix = aSelectedServer
        SetSelectedServer(aSelectedServer)
    }

    const AuthUrl = "https://app<BaseUrlPrefix>.intercom.com/oauth" +
        "?client_id=1cf6e674-c3ca-4bad-9c48-8ff92184b237"
    const TokenUrl = "https://api<BaseUrlPrefix>.intercom.io/auth/eagle/token" 
    const TokenBody = "client_id=1cf6e674-c3ca-4bad-9c48-8ff92184b237&client_secret=7fbef984-e333-4b6b-8811-cdf0d4bdf4b2&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return mServers.length > 0 ?
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("Region").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue">
                <select name="Region" value={mSelectedServer} onChange={evt => SetSelectedServerEx(evt.target.value)} className="wizardinputfield">
                    {mServers.map((server, index) => <option key={"server" + index} value={server.BaseUrlPrefix}>{server.Country}</option>)}
                </select>
            </div>
            <DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl.replace("<BaseUrlPrefix>",mSelectedServer)} TokenUrl={TokenUrl.replace("<BaseUrlPrefix>",mSelectedServer)} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>
        </div> :
        <div>Waiting...</div>
}

DatasourceIntercom.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceIntercom