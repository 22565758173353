import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceQuickBase = (props) => {

    const Fields = [
        {Identifier:"Username", Translation:Translate("Username"), Required:true},
        {Identifier:"Password", Translation:Translate("Password"), Required:true, PasswordField:true},
        {Identifier:"Apptoken", Translation:Translate("App token"), Required:true},
        {Identifier:"Domain", Translation:Translate("Domain"), Required:true},
        {Identifier:"DatabaseId", Translation:Translate("Database ID"), Required:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceQuickBase.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceQuickBase