import { KolibriLIVE_Finalize } from "./DatasourceKolibriLIVE.jsx"
import { Redtail_Finalize } from "./DatasourceRedtail.jsx"

const cDatasources = [
    {id:"Test", name:"Test application", live:false, autochecksettingswhenready:true},
    {id:"Act", name:"Act! Cloud", live:false, autochecksettingswhenready:false},
    {id:"actessentials simple", name:"Act! Essentials", live:false, autochecksettingswhenready:false},
    {id:"activecampaign simple", name:"ActiveCampaign", live:false, autochecksettingswhenready:false},
    {id:"afasonline simple", name:"Afas Online", live:false, autochecksettingswhenready:false},
    {id:"afas simple", name:"Afas Profit", live:false},
    {id:"Archie LIVE", name:"Archie", live:true, autochecksettingswhenready:false},
    {id:"AuditCase LIVE", name:"AuditCase", live:true, autochecksettingswhenready:false},
    {id:"Autotask", name:"Autotask", live:false, autochecksettingswhenready:false},
    {id:"Bitrix24", name:"Bitrix24", live:false, autochecksettingswhenready:false},
    {id:"Bullhorn", name:"Bullhorn", live:false, autochecksettingswhenready:true},
    {id:"BusinessCentral", name:"Business Central", live:false, autochecksettingswhenready:true},
    {id:"carerix", name:"Carerix", live:false, autochecksettingswhenready:false},
    {id:"Chatwoot", name:"Chatwoot", live:false, autochecksettingswhenready:false},
    {id:"Clio", name:"Clio", live:false, autochecksettingswhenready:false},
    {id:"connectwise simple", name:"ConnectWise", live:false, autochecksettingswhenready:false},
    {id:"Comelit LIVE", name:"Comelit", live:true, autochecksettingswhenready:false},
    {id:"DPlan", name:"DPlan", live:false, autochecksettingswhenready:false},
    {id:"DynamicsEmpire LIVE", name:"Dynamics Empire", live:true, autochecksettingswhenready:false},
    {id:"DynamicsNavision", name:"Microsoft Dynamics Navision", live:false, autochecksettingswhenready:false},
    {id:"Efficy", name:"Efficy", live:false, autochecksettingswhenready:false},
    {id:"ExactOnline", name:"ExactOnline", live:false, autochecksettingswhenready:false},
    {id:"FasterForwardElements", name:"FasterForward Elements", live:false, autochecksettingswhenready:false},
    {id:"FileVine", name:"FileVine", live:false, autochecksettingswhenready:false},
    {id:"Flexkids LIVE", name:"Flexkids", live:true, autochecksettingswhenready:false},
    {id:"Forth LIVE", name:"Forth", live:true, autochecksettingswhenready:false},
    {id:"freshdesk simple", name:"Freshdesk", live:false, autochecksettingswhenready:false},
    {id:"freshservice simple", name:"FreshService", live:false, autochecksettingswhenready:false},
    {id:"Front", name:"Front", live:false, autochecksettingswhenready:false},
    {id:"fuse5 simple", name:"Fuse5", live:false, autochecksettingswhenready:false},
    {id:"FysioManager", name:"Fysio manager", live:false, autochecksettingswhenready:false},
    {id:"GarageSoftware", name:"Garage Software", live:false, autochecksettingswhenready:false},
    //Temporarily disabled -- {id:"GoogleMaps LIVE", name:"Google Maps", live:true, autochecksettingswhenready:false},
    {id:"Gripp", name:"Gripp", live:false, autochecksettingswhenready:false},
    {id:"GSuite", name:"GSuite", live:false, autochecksettingswhenready:true},
    {id:"HaloPSA", name:"HaloPSA", live:false, autochecksettingswhenready:false},
    {id:"highrise simple", name:"Highrise", live:false, autochecksettingswhenready:false},
    {id:"hubspot simple", name:"Hubspot", live:false, autochecksettingswhenready:true},
    {id:"InfusionSoft", name:"InfusionSoft", live:false, autochecksettingswhenready:true},
    {id:"Insightly", name:"Insightly", live:false, autochecksettingswhenready:false},
    {id:"Intercom", name:"Intercom", live:false, autochecksettingswhenready:true},
    {id:"Kolibri LIVE", name:"Kolibri", live:true, autochecksettingswhenready:false},
    {id:"LeadSquared", name:"LeadSquared", live:false, autochecksettingswhenready:false},
    {id:"Legalsense", name:"Legalsense", live:false, autochecksettingswhenready:false},
    {id:"Lightspeed", name:"Lightspeed", live:false, autochecksettingswhenready:false},
    {id:"Lime", name:"Lime", live:false, autochecksettingswhenready:false},
    {id:"Moneybird", name:"Moneybird", live:false, autochecksettingswhenready:false},
    {id:"msdcrmonline odata simple", name:"Microsoft Dynamics Online", live:false, autochecksettingswhenready:true},
    {id:"myphoner", name:"MyPhoner", live:false, autochecksettingswhenready:false},
    {id:"Nanny4All", name:"Nanny4All", live:false, autochecksettingswhenready:false},
    {id:"NxtPeople LIVE", name:"NxtPeople", live:true, autochecksettingswhenready:false},
    {id:"Odoo", name:"Odoo", live:false, autochecksettingswhenready:false},
    {id:"Office", name:"Office", live:false, autochecksettingswhenready:true},
    {id:"OpenDental", name:"OpenDental", live:false, autochecksettingswhenready:false},
    {id:"Outreach", name:"Outreach", live:false, autochecksettingswhenready:true},
    {id:"OutSmart", name:"OutSmart", live:false, autochecksettingswhenready:false},
    {id:"perfectviewonline simple", name:"PerfectView Online", live:false, autochecksettingswhenready:true},
    {id:"pipedrive simple", name:"Pipedrive", live:false, autochecksettingswhenready:false},
    {id:"pipelinedeals simple", name:"Pipeline Deals", live:false, autochecksettingswhenready:false},
    {id:"QuickBase", name:"QuickBase", live:false, autochecksettingswhenready:false},
    {id:"QuickBooks", name:"QuickBooks", live:false, autochecksettingswhenready:false},
    {id:"Redtail", name:"Redtail", live:false, autochecksettingswhenready:false},
    {id:"rpmtelco simple", name:"RPMTelco", live:false, autochecksettingswhenready:false},
    {id:"SageCRM", name:"Sage CRM", live:false, autochecksettingswhenready:false},
    {id:"salesforce simple", name:"Salesforce", live:false, autochecksettingswhenready:false},
    {id:"Sanday LIVE", name:"Sanday", live:true, autochecksettingswhenready:false},
    {id:"Sharpspring", name:"Sharpspring", live:false, autochecksettingswhenready:false},
    {id:"simplicate simple", name:"Simplicate", live:false, autochecksettingswhenready:false},
    {id:"SnelStart", name:"SnelStart", live:false, autochecksettingswhenready:false},
    {id:"SugarCRMOnline", name:"Sugar CRM Online", live:false, autochecksettingswhenready:false},
    {id:"SyncroMSP LIVE", name:"Syncro MSP", live:true, autochecksettingswhenready:false},
    {id:"Teamleader", name:"Teamleader", live:false, autochecksettingswhenready:true},
    {id:"TheOfficeManager", name:"The Office Manager", live:false, autochecksettingswhenready:false},
    {id:"Tim LIVE", name:"Tim", live:true, autochecksettingswhenready:false},
    {id:"topdesk simple", name:"Topdesk", live:false, autochecksettingswhenready:false},
    {id:"Trengo", name:"Trengo", live:false, autochecksettingswhenready:false},
    {id:"tribecrm", name:"TribeCRM", live:false, autochecksettingswhenready:true},
    {id:"vtiger simple", name:"vtiger", live:false, autochecksettingswhenready:false},
    {id:"Wealthbox", name:"Wealthbox", live:false, autochecksettingswhenready:false},
    {id:"webCRM", name:"webCRM", live:false, autochecksettingswhenready:false},
    {id:"WeClapp", name:"WeClapp", live:false, autochecksettingswhenready:false},
    {id:"Workbooks", name:"Workbooks", live:false, autochecksettingswhenready:false},
    {id:"Zendesk", name:"Zendesk", live:false, autochecksettingswhenready:false},
    {id:"zohocrm simple", name:"Zoho", live:false, autochecksettingswhenready:true},
    {id:"ZohoBigin", name:"Zoho Bigin", live:false, autochecksettingswhenready:true},
    {id:"zohocrm2 simple", name:"Zoho EU", live:false, autochecksettingswhenready:true},
    {id:"ZohoDesk", name:"ZohoDesk", live:false, autochecksettingswhenready:true}
]

export function GetDatasources() 
{
    return cDatasources
}

export function GetDatasourceUnavailable(aId)
{
    try
    {
        return cDatasources.filter(item => {return item.id === aId}).length === 0
    }
    catch { return false }
}

export function GetDatasourceLive(aId)
{
    try
    {
        return cDatasources.filter(item => {return item.id === aId})[0].live
    }
    catch { return false }
}

export function GetDatasourceName(aId)
{
    try
    {
        return cDatasources.filter(item => {return item.id === aId})[0].name
    }
    catch { return aId }
}

export function GetDatasourceAutoCheckSettingsWhenReady(aId)
{
    try
    {
        return cDatasources.filter(item => {return item.id === aId})[0].autochecksettingswhenready
    }
    catch { return false }
}

export function GetDatasourceTempSettings(aId)
{
    try
    {
        return cDatasources.filter(item => {return item.id === aId})[0].tempsettings
    }
    catch { return "" }
}

export function FinalizeDatasource(aIntegrationSettings)
{
    if (aIntegrationSettings.DatasourceType === "Kolibri LIVE") { return KolibriLIVE_Finalize(aIntegrationSettings) }
    else if (aIntegrationSettings.DatasourceType === "Redtail") { return Redtail_Finalize(aIntegrationSettings) }
    else return true;
}

export function DatasourceRemoveGetDataOnlySettings(aIntegrationSettings)
{
    if (aIntegrationSettings.DatasourceType === "BusinessCentral") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Clio") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "ExactOnline") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "GSuite") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "hubspot simple") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "InfusionSoft") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Intercom") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Moneybird") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "msdcrmonline odata simple") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Office") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Outreach") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "QuickBooks") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "Teamleader") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "tribecrm") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "ZohoBigin") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "zohocrm simple") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "zohocrm2 simple") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
    if (aIntegrationSettings.DatasourceType === "ZohoDesk") { aIntegrationSettings.DatasourceSettings["AccessToken"] = "" }
}