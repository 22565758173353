import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import IntegrationWizardHeader from "./IntegrationWizard/IntegrationWizardHeader.jsx";
import IntegrationWizardNavigation from "./IntegrationWizard/IntegrationWizardNavigation.jsx";
import IntegrationWizardStepApplication from "./IntegrationWizard/IntegrationWizardStepApplication.jsx"; //Step 1
import IntegrationWizardStepDatasourceType from "./IntegrationWizard/IntegrationWizardStepDatasourceType.jsx"; //Step 2
import IntegrationWizardStepApplicationDatasource from "./IntegrationWizard/IntegrationWizardStepApplicationDatasource.jsx"; //Step 3
import IntegrationWizardStepDatasourceSettings from "./IntegrationWizard/IntegrationWizardStepDatasourceSettings.jsx"; //Step 4
import IntegrationWizardStepDatasourceInformation from "./IntegrationWizard/IntegrationWizardStepDatasourceInformation.jsx"; //Step 5
import IntegrationWizardStepCountryCode from "./IntegrationWizard/IntegrationWizardStepCountryCode.jsx"; //Step 6
import IntegrationWizardStepSynchronizationSettings from "./IntegrationWizard/IntegrationWizardStepSynchronizationSettings.jsx"; //Step 7
import IntegrationWizardStepClientNotification from "./IntegrationWizard/IntegrationWizardStepClientNotification.jsx"; //Step 8
import IntegrationWizardStepScripts from "./IntegrationWizard/IntegrationWizardStepScripts.jsx"; //Step 9
import IntegrationWizardStepReview from "./IntegrationWizard/IntegrationWizardStepReview.jsx"; //Step 10

import { ConfigurationApiCallFieldScriptsAdd, ConfigurationApiCheckScheduler, ConfigurationApiDatasourceData, ConfigurationApiCRMDatasourcesGet, ConfigurationApiIntegrationCreate, ConfigurationApiIntegrationUpdateAll } from "../ExternalApi/ConfigurationApi.js";
import { GetDatasourceName, GetDatasourceLive, FinalizeDatasource, DatasourceRemoveGetDataOnlySettings } from "./Datasources/Datasources.jsx"
import { GetDelphiDateTimeNow } from "./GeneralFunctions.jsx"
import { GetLanguageCode, Translate } from "./Translator.jsx"

import "../css/IntegrationWizard.css";

const IntegrationWizard = (props) => {

    const mHistory = useHistory();

    const [mRefresher, SetRefresher] = useState(false);
    const [mApplication, SetApplication] = useState("");
    const [mApplicationDatasources, SetApplicationDatasources] = useState(null);
    const [mApplicationDatasourceSelectedIndex, SetApplicationDatasourceSelectedIndex] = useState(0);
    const [mIntegrationSettings, SetIntegrationSettings] = useState(props.IntegrationSettings);
    const [mDatasourceTestData, SetDatasourceTestData] = useState([]); //The testdata returned from the cloudrucs getdata

    const [mCurrentStep, SetCurrentStep] = useState(props.Action === "setscripts" ? 9 : (props.Action === "setnotification" ? 8 : (props.Action === "setcountrycode" ? 6 : (props.Action === "update" ? 4 : 1))));
    const [mPreviousSteps, SetPreviousSteps] = useState([]);
    const [mNextButtonEnabled, SetNextButtonEnabled] = useState(true);
    const [mCurrentDirectionForward, SetCurrentDirectionForward] = useState(true); //determines when skipping a step whether to go back or forward

    const [mDatasourceSettingsError, SetDatasourceSettingsError] = useState("");

    const [mInnerWidth,SetInnerWidth] = useState(window.innerWidth)

    useEffect(() => {

        const ResetWidth = () => {
            SetInnerWidth(window.innerWidth)
        }

        const Interval = setInterval(() => ResetWidth(), 1000); 
        return () => clearInterval(Interval);
      }, []); 

    //Events

    const OnNextButtonClicked = async () => {
        SetNextButtonEnabled(true) //true tenzij een frame zichzelf op false zet
        
        if (mCurrentStep === 4)
        {
            if (!await CheckDatasourceSettings())
            {
                return
            }
        }
        
        if (IsLastPage())
        {     
            if ((props.Action === "setscripts") ||  (props.Action === "update"))
            {
                mIntegrationSettings.Scripts.map((script) => { script.LastModified = GetDelphiDateTimeNow() })
            }

            if (mIntegrationSettings.IntegrationGuid)
            {
                await ConfigurationApiIntegrationUpdateAll(props.SecurityToken, mIntegrationSettings)
                await ConfigurationApiCheckScheduler(props.SecurityToken, mIntegrationSettings.IntegrationGuid)
                Finish()
            }
            else
            {
                if (mIntegrationSettings.DatasourceType === "none")
                {
                    //If the datasourcetype is none, then this is really a callfield script that should be added                    
                    await ConfigurationApiCallFieldScriptsAdd(props.SecurityToken, mIntegrationSettings.Scripts)
                    
                    localStorage.setItem("SelectedIntegrationGuid", "");
                    localStorage.setItem("SelectedCallfieldScript", "last");
                    
                    Finish()
                }
                else
                {   
                    const AddedIntegrationGuid = await ConfigurationApiIntegrationCreate(props.SecurityToken, FinalizedIntegrationSettings())
                    await ConfigurationApiCheckScheduler(props.SecurityToken, AddedIntegrationGuid)

                    localStorage.setItem("SelectedIntegrationGuid", AddedIntegrationGuid);
                    localStorage.setItem("SelectedCallfieldScript", "");

                    Finish()
                }
            }
        }
        else 
        {
            window.scrollTo(0,0)
            if (mCurrentStep === 4)
            {
                if (GetDatasourceLive(props.IntegrationSettings.DatasourceType)) { GoNext(8); } //Skip countrycode and sync settings for live datasources
                else { GoNext(8); }
            }  
            else if (mCurrentStep === 5) { GoNext(9); }
            else { GoNext(mCurrentStep + 1) }  
        }
    } 

    const OnStep1ApplicationSelected = async (aSecurityToken, aApplication) => {
        window.scrollTo(0,0)   
        if (aApplication)
        {   
            SetApplication(aApplication)
            mIntegrationSettings.ApplicationName = aApplication
            SetApplicationDatasources([])
            SetApplicationDatasourceSelectedIndex(-1)
            ConfigurationApiCRMDatasourcesGet(aSecurityToken, aApplication).then(applicationDatasources => SetApplicationDatasources(applicationDatasources))  
        }
        else
        {
            SetApplication("")
            mIntegrationSettings.ApplicationName = ""
            SetApplicationDatasources([])
            SetApplicationDatasourceSelectedIndex(0)
            GoNext(2)
        }    
    }
    
    const OnStep2DatasourceTypeSelected = (aDatasourceType) => {
        window.scrollTo(0,0)    
        mIntegrationSettings.DatasourceType = aDatasourceType
        GoNext(4)
    }

    const OnStep3ApplicationDatasourceSelected = (aIndex) => {
        window.scrollTo(0,0)
        SetApplicationDatasourceSelectedIndex(aIndex)
        ReadPredefinedSettings(aIndex)
        GoNext(mApplicationDatasources[aIndex].DatasourceType === "none" ? 5 : 4)
    }

    //Helper functions

    const CheckDatasourceSettings = async () => { 

        //2023-12-18: trim spaces from the values
        Object.keys(mIntegrationSettings.DatasourceSettings).map((key) => mIntegrationSettings.DatasourceSettings[key] = TrimValue(mIntegrationSettings.DatasourceSettings[key]))

        SetDatasourceSettingsError("Checking CRM settings") //Do not change this text - see IntegrationWizardStepDatasourceSettings.jsx
        if (await FinalizeDatasource(mIntegrationSettings))
        {
            const DatasourceDataResult = await ConfigurationApiDatasourceData(props.SecurityToken, mIntegrationSettings.DatasourceType, mIntegrationSettings.DatasourceSettings)           
            if (DatasourceDataResult.data)
            {        
                const DatasourceData = DatasourceDataResult.data
                SetDatasourceTestData(DatasourceData.DatasourceRows)
                mIntegrationSettings.DatasourceFields = DatasourceData.DatasourceFields
                mIntegrationSettings.DatasourceSettings = DatasourceData.DatasourceSettings
                DatasourceRemoveGetDataOnlySettings(mIntegrationSettings)

                if (GetDatasourceLive(mIntegrationSettings.DatasourceType) || DatasourceData.DatasourceRows.length > 0) 
                {    
                    if (DatasourceData.DatasourceRows.length > 0)       
                    {         
                        {mIntegrationSettings.DatasourceFields.map((field,index) => 
                            field.DefaultTestValue = DatasourceData.DatasourceRows[0][index]
                        )}  
                    }
                    SetDatasourceSettingsError("")
                    return true
                }
                else
                {
                    SetDatasourceSettingsError("Empty data set returned")                    
                    return false
                }
            }
            else
            {
                SetDatasourceSettingsError(DatasourceDataResult.error ? DatasourceDataResult.error : "Failed to retrieve data")                   
                return false
            }
        }
        else
        {
            SetDatasourceSettingsError("Invalid datasource settings")
        }
    }

    const FinalizedIntegrationSettings = () => {
        let IntegrationSettings = mIntegrationSettings   
        IntegrationSettings.ApplicationName = IntegrationSettings.ApplicationName ? IntegrationSettings.ApplicationName : GetDatasourceName(IntegrationSettings.DatasourceType)
        
        //tbd: set field disableds

        return IntegrationSettings
    }

    const Finish = () => {
        if (props.After === "main")
        {
            mHistory.push("/main")
        }
        else
        {
            if (window.opener !== null)
            {
                window.opener.location.reload(true)
                window.opener = null
            }
            window.close()
        }     
    }  

    const IsLastPage = () => {
        if (props.Action === "setcountrycode") { return (mCurrentStep === 6) }
        if (props.Action === "setnotification") { return (mCurrentStep === 8) }
        if (props.Action === "setscripts") { return (mCurrentStep === 9) }
        if (props.Action === "update") { return (mCurrentStep === 10) }
        else return (mCurrentStep === 10)
    }

    const GoBack = () => {
        SetCurrentDirectionForward(false)
        if (mPreviousSteps.length > 0)
        {
            SetCurrentStep(mPreviousSteps[mPreviousSteps.length - 1])
            SetPreviousSteps(mPreviousSteps.slice(0,-1))
        }
    }

    const GoNext = (aStep) => {
        SetCurrentDirectionForward(true)
        SetPreviousSteps([...mPreviousSteps,mCurrentStep])
        SetCurrentStep(aStep)   
    }

    const NotificationLinePredefinedToTemplate = (aNotificationLinePredefined) => {
        let Line = ""
        for (const NotificationLinePredefinedItem of aNotificationLinePredefined) 
        {
            if (NotificationLinePredefinedItem["text"])
            {        
                const NotificationLinePredefinedItemInner = NotificationLinePredefinedItem["text"]
                
                const LanguageCode = GetLanguageCode() ?? "default" 
                Line += (NotificationLinePredefinedItemInner[LanguageCode] ?? NotificationLinePredefinedItemInner["default"] ?? "")
            }
            else if (NotificationLinePredefinedItem["recognitionfield"])
            {
                Line += "$(" +  NotificationLinePredefinedItem["recognitionfield"] + ")"
            }
            else if (NotificationLinePredefinedItem["callfield"])
            {
                Line += "#(" +  NotificationLinePredefinedItem["callfield"] + ")"
            }
        }

        return Line
    }

    const PredefinedScriptToScript = (aPredefinedScript) => {
        const LanguageCode = GetLanguageCode() ?? "default"  

        let Script = {}
        Script.Name = aPredefinedScript.Header[LanguageCode]
        Script.SubHeader = aPredefinedScript.Subheader[LanguageCode]
        Script.XmlFile = aPredefinedScript.Id
        Script.XmlApplication = mApplication ?? ""
        Script.ScriptType = aPredefinedScript.Type
        Script.Enabled = true
        Script.CSDFileName = aPredefinedScript.BaseSettings["csdfilename"]

        Script.CSDParams = {}
        if (aPredefinedScript.BaseSettings["csdparameters"])
        {
            aPredefinedScript.BaseSettings["csdparameters"].map(csdparam => Script.CSDParams[csdparam.identifier] = csdparam.default)
        }

        Script.WebPage = ""
        if (aPredefinedScript.BaseSettings["webpage"])
        {
            Script.WebPage = aPredefinedScript.BaseSettings["webpage"]
        }

        Script.IncludeRecognitionFields = true
        Script.IncludeCallFields = true
        Script.AutoExecute = aPredefinedScript.AutoExecute

        return Script
    }

    const ReadPredefinedNotification = (applicationDatasourceIndex) => {
        if ((mApplicationDatasources[applicationDatasourceIndex].NotificationTemplate !== null) && (mApplicationDatasources[applicationDatasourceIndex].NotificationTemplate.length > 0))
        {            
            mIntegrationSettings.NotificationTemplate.Header = NotificationLinePredefinedToTemplate(mApplicationDatasources[applicationDatasourceIndex].NotificationTemplate[0])
            
            mIntegrationSettings.NotificationTemplate.Body = []
            for (var i = 1; i < mApplicationDatasources[applicationDatasourceIndex].NotificationTemplate.length; i++ ) 
            {
                mIntegrationSettings.NotificationTemplate.Body.push(NotificationLinePredefinedToTemplate(mApplicationDatasources[applicationDatasourceIndex].NotificationTemplate[i]))
            }
        }   
    }

    const ReadPredefinedScripts = (applicationDatasourceIndex) => {
        mIntegrationSettings.Scripts = []
        if (mApplicationDatasources[applicationDatasourceIndex].Scripts && (mApplicationDatasources[applicationDatasourceIndex].Scripts !== null))
        {
            mApplicationDatasources[applicationDatasourceIndex].Scripts.map(applicationscript => { 
                const Script = props.PredefinedScripts.find(script => script.Id === applicationscript.ScriptId)
                if (Script)
                {               
                    let NewScript = PredefinedScriptToScript(Script)
                    NewScript.InitialState = applicationscript.InitialState 
                    mIntegrationSettings.Scripts = [...mIntegrationSettings.Scripts, NewScript]
                }
                return true
            })
        }
    }

    const ReadPredefinedSettings = (applicationDatasourceIndex) => {
        ReadPredefinedNotification(applicationDatasourceIndex)

        ReadPredefinedScripts(applicationDatasourceIndex)

        SetRefresher(!mRefresher)
    }

    const SelectedApplicationDatasource = () => {
        if (mApplicationDatasources && (mApplicationDatasources.length > mApplicationDatasourceSelectedIndex) && (mApplicationDatasourceSelectedIndex > -1))
        {
            return mApplicationDatasources[mApplicationDatasourceSelectedIndex]
        }
        else
        {
            return null
        }
    }

    const StepHeader = () => {
        if ((mApplicationDatasources !== null) && (mApplicationDatasourceSelectedIndex > -1) && (mApplicationDatasources.length > mApplicationDatasourceSelectedIndex))
        {
            const LanguageCode = GetLanguageCode() ?? "default"           
            const StepHeaders = mApplicationDatasources[mApplicationDatasourceSelectedIndex].StepHeaders
            if (StepHeaders)
            {
                const CurrentStepHeader = StepHeaders.find(stepHeader => stepHeader.step === StepName(mCurrentStep) && stepHeader.header[LanguageCode]);
                if (CurrentStepHeader)
                {
                    return CurrentStepHeader.header[LanguageCode]
                }
            }
        }
        return "";
    }

    const StepName = (aCurrentStep) => {
        switch(aCurrentStep)
        {
            case 4: return "datasourcesettings";
            case 5: return "datasourceinformation";
            case 8: return "clientnotification";
            case 9: return "scripts";
            default: return "";
        }
    }

    const StepSubHeader = () => {
        if ((mApplicationDatasources !== null) && (mApplicationDatasourceSelectedIndex > -1) && (mApplicationDatasources.length > mApplicationDatasourceSelectedIndex))
        {
            const LanguageCode = GetLanguageCode() ?? "default"  
            const StepHeaders = mApplicationDatasources[mApplicationDatasourceSelectedIndex].StepHeaders
            if (StepHeaders)
            {
                const CurrentStepHeader = StepHeaders.find(stepHeader => stepHeader.step === StepName(mCurrentStep) && stepHeader.subheader[LanguageCode]);
                if (CurrentStepHeader)
                {
                    return CurrentStepHeader.subheader[LanguageCode]
                }
            }
        }
        return "";
    }

    const TrimValue = (aValue) => {
        try
        {
            return aValue.trim()
        }
        catch
        {
            return aValue
        }
    }

    //Make step
    //const StepWidth = (window.innerWidth > 1240) ? 1200 : (window.innerWidth - 40)
    const StepWidth = (window.innerWidth - 40)
    const StepFrame = 
        mCurrentStep === 1 ? <IntegrationWizardStepApplication StepWidth={StepWidth} SecurityToken={props.SecurityToken} OnApplicationSelected={OnStep1ApplicationSelected}/> : 
        mCurrentStep === 2 ? <IntegrationWizardStepDatasourceType StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings} OnDatasourceTypeSelected={OnStep2DatasourceTypeSelected}/> : 
        mCurrentStep === 3 ? <IntegrationWizardStepApplicationDatasource StepWidth={StepWidth} Application={mApplication} ApplicationDatasources={mApplicationDatasources} OnApplicationDatasourceSelected={OnStep3ApplicationDatasourceSelected}/> : 
        mCurrentStep === 4 ? <IntegrationWizardStepDatasourceSettings StepWidth={StepWidth} SecurityToken={props.SecurityToken} Header={StepHeader()} SubHeader={StepSubHeader()} IntegrationSettings={mIntegrationSettings} DatasourceSettingsError={mDatasourceSettingsError} OnNextButtonEnabled={SetNextButtonEnabled}/> : 
        mCurrentStep === 5 ? <IntegrationWizardStepDatasourceInformation StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings} Application={mApplication} Header={StepHeader()} SubHeader={StepSubHeader()} OnNextButtonEnabled={SetNextButtonEnabled}/> : 
        mCurrentStep === 6 ? <IntegrationWizardStepCountryCode StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings} OnNextButtonEnabled={SetNextButtonEnabled}/> : 
        mCurrentStep === 7 ? <IntegrationWizardStepSynchronizationSettings StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings}/> :
        mCurrentStep === 8 ? <IntegrationWizardStepClientNotification StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings} ApplicationDatasource={SelectedApplicationDatasource()}/> :
        mCurrentStep === 9 ? <IntegrationWizardStepScripts StepWidth={StepWidth} SecurityToken={props.SecurityToken} Header={StepHeader()} SubHeader={StepSubHeader()} ApplicationDatasource={SelectedApplicationDatasource()} IntegrationSettings={mIntegrationSettings} PredefinedScripts={props.PredefinedScripts} OnNextButtonEnabled={SetNextButtonEnabled}/> : 
        mCurrentStep === 10 ? <IntegrationWizardStepReview StepWidth={StepWidth} IntegrationSettings={mIntegrationSettings} OnNextButtonEnabled={SetNextButtonEnabled}/> : 
        <div>Something went wrong. Step {mCurrentStep} is an unknown step</div>

    if ((mCurrentStep === 1) && (mApplicationDatasources !== null) && (mApplicationDatasources.length > 0) && (mApplicationDatasourceSelectedIndex === -1))
    {
        //If you set an application with datasources and we're still on step 1, then proceed
        //mApplicationDatasourceSelectedIndex=-1 is used so this will not be executed again if we choose to go back
        mIntegrationSettings.WizardSettings.application = mApplication
        if (mApplicationDatasources.length > 1)
        {
            //the application has multiple datasources
            SetApplicationDatasourceSelectedIndex(0)
            GoNext(3)
        }
        else if (mApplicationDatasources.length === 1)
        {
            if (mApplicationDatasources[0].DatasourceType === "none")
            {
                //the datasource has no crm number import. show datasourceinformation
                SetApplicationDatasourceSelectedIndex(0)
                GoNext(5)
            }
            else
            {
                //the datasource has crm number import. show datasourcesettings
                mIntegrationSettings.DatasourceType = mApplicationDatasources[0].DatasourceType
                SetApplicationDatasourceSelectedIndex(0)
                GoNext(4)
            }  

            ReadPredefinedSettings(0)
            return true
        }
    }
    else
    {
        const SelectedDatasource = SelectedApplicationDatasource()
        if (SelectedDatasource && (SelectedDatasource.SkipSteps !== null) && (SelectedDatasource.SkipSteps !== undefined))
        {
            if (SelectedDatasource.SkipSteps.indexOf(StepName(mCurrentStep)) > -1)
            {
                if (mCurrentDirectionForward)
                {
                    OnNextButtonClicked()
                }
                else
                {
                    GoBack()
                }
            }
        }
    }

    //Paint
    
    const BrandingColor = sessionStorage.getItem("BrandingColor")

    return ( 
        <table className="main" cellPadding="0" cellSpacing="0">  
        <tbody> 
        <tr height="10">
            <td width="*"/>
            <td width={StepWidth}/>
            <td width="*"/>
        </tr>
        <tr height="64">
            <td className="wizardheader"/>
            <td height="100%">

                <IntegrationWizardHeader Action={props.Action} CurrentStep={mCurrentStep}/>

            </td>
            <td className="wizardheader"/>
        </tr>
        <tr height="10"/>
        <tr>
            <td/>
            <td>{StepFrame}</td>
            <td/>
        </tr>
        <tr height="20"/>
        <tr height="70">
            <td className="wizardfooter" style={{ backgroundColor: BrandingColor}}></td>
            <td className="wizardfooter" style={{ backgroundColor: BrandingColor}}>
                <IntegrationWizardNavigation BackVisible={mPreviousSteps.length > 0} NextVisible={mCurrentStep !== 1} NextEnabled={mNextButtonEnabled || false} NextCaption={IsLastPage() ? Translate("FINISH") : Translate("NEXT")} OnBack={GoBack} OnNext={OnNextButtonClicked}/>
            </td>
            <td className="wizardfooter" style={{ backgroundColor: BrandingColor}}></td>
        </tr>
        <tr height="40">
            <td/>
            <td className="wizardbottomcloudcti">&#169; 2024 CloudCTI</td>
            <td/>
        </tr>
        </tbody>
        </table>
    )
}

IntegrationWizard.propTypes = {
    SecurityToken: PropTypes.string,
    Action: PropTypes.string,
    IntegrationGuid: PropTypes.string,
    IntegrationSettings: PropTypes.object,
    After: PropTypes.string,
    PredefinedScripts: PropTypes.array
  }

export default IntegrationWizard;