import React from "react";
import PropTypes from 'prop-types';

import PdfPng from '../../Assets/Pdf.png';

const IntegrationWizardStepHeader = (props) => {

    const OpenPdf = () => {
        window.open(props.PdfUrl, "_blank")
    }

    const BrandingColor = sessionStorage.getItem("BrandingColor")

    return (        
        <div>
            <div className="wizardstepheader">
                {props.Header}
                {props.PdfUrl ? <span>&nbsp;<img src={PdfPng} alt="pdf" width="13" height="13" className="linkitem" onClick={OpenPdf}/></span> : ""}
            </div>
            <table><tbody><tr height="10"/></tbody></table>
            <table cellPadding="0" cellSpacing="0" width="50" height="10">
            <tbody>
                <tr>
                    <td className="wizardbluebar" style={{ backgroundColor: BrandingColor}}></td>
                </tr>
            </tbody>     
            </table>
            {props.SubHeader ? <br/> : ""}
            {props.SubHeader ? <div className="wizardstepsubheader">{props.SubHeader}</div> : ""}
            <br/>
            <table><tbody><tr height="10"/></tbody></table>
        </div>
    );
}

IntegrationWizardStepHeader.propTypes = {
  Header: PropTypes.string,
  SubHeader: PropTypes.string,
  PdfUrl: PropTypes.string
}

export default IntegrationWizardStepHeader