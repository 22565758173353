import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceAfasOnline = (props) => {

    const Fields = [
        {Identifier:"ParticipantNumber", Translation:Translate("Participant number"), Required:true},
        {Identifier:"TokenXml", Translation:Translate("Token XML"), Required:true},
        {Identifier:"DatasourceVersion", Translation:"", Required:true, StaticValue:"5"}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceAfasOnline.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceAfasOnline