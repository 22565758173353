import React, {useCallback, useEffect, useState, useRef} from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import { Button } from "react-bootstrap";
import { OAuthApiStartPolling, OAuthApiStopPolling } from "../../ExternalApi/OAuthApi"
import { ConfigurationApiForward } from "../../ExternalApi/ConfigurationApi"
import IntegrationWizardDots from "../IntegrationWizard/IntegrationWizardDots"

const DatasourceBusinessCentral = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 
    const [mWaitingForState, SetWaitingForState] = useState("")
    const [mError, SetError] = useState("") 
    const [mOAuthWindow, SetOAuthWindow] = useState(null) 

    const cOAuthUrlBase = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
        "?scope=offline_access+https://api.businesscentral.dynamics.com/user_impersonation" +
        "&client_id=d2b5e92c-dadf-4d33-9dab-b6b5517f8773" +
        "&response_type=code" +
        "&response_mode=query" + 
        "&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2"

    useEffect(() => {
        SetReady()
      }, []); 

    const UpdateDatasourceSetting = (aNewValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aNewValue

        SetReady()
    }

    const SetReady = () => 
    {
        props.OnSetReady(props.IntegrationSettings.DatasourceSettings.RefreshToken)
    }

    async function GetTokens(aAuthorizationCode) 
    {
          let Result = await ConfigurationApiForward(
            props.SecurityToken, 
            "https://login.microsoftonline.com/common/oauth2/v2.0/token", 
            "POST", 
            "client_id=d2b5e92c-dadf-4d33-9dab-b6b5517f8773&client_secret=q=d_=950Fxzn3seCwrs/d.8NeF:5cDOS&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2&grant_type=authorization_code&code=" + aAuthorizationCode, 
            "application/json", 
            "application/x-www-form-urlencoded")
        if (Result != null)
        {
            UpdateDatasourceSetting(Result.data.access_token, "AccessToken")
            UpdateDatasourceSetting(Result.data.refresh_token, "RefreshToken")
        }
        else
        {
            SetError("Failed to retrieve tokens")
        }

        SetWaitingForState("")
    }

    const OAuthPollResult = (aResult, aValue) =>
    {
        if (aResult)
        {
            console.log("OAuthPollResult success: " + JSON.stringify(aValue))
            if (aValue.Code && aValue.ResponseUrl)
            {
                GetTokens(aValue.Code)
            }
            else
            {
                SetError("Failed to authenticate. No authorizationcode found")  
            }
        }
        else
        {
            console.log("OAuthPollResult error: " + JSON.stringify(aValue))  
            SetError("Failed to authenticate")          
        }
    }

    const RandomState = () => 
    {
        let Result = "client2020WEB"

        var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for ( var i = 0; i < 27; i++ ) 
        {
            Result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        
        return Result
    }

    const StartOAuth = () =>
    {
        const State = RandomState()

        const OAuthUrl = cOAuthUrlBase + "&state=" + State
        const OAuthWindow = window.open(OAuthUrl, "_blank", 'popup=yes,titlebar=0,left=' + ((screen.availWidth - 750)/2)  + ',top=' + ((screen.availHeight - 750)/2)  + ',width=750,height=750')
        SetOAuthWindow(OAuthWindow)
        OAuthApiStartPolling(State, OAuthPollResult, OAuthWindow)

        SetWaitingForState(State)
    }

    const StopOAuth = () =>
    {
        try 
        { 
            if (mOAuthWindow !== null)
            {
                mOAuthWindow.close() 
                SetOAuthWindow(null)
            }
        } 
        catch {}
        
        OAuthApiStopPolling()

        SetWaitingForState("")
    }

    const Body = props.IntegrationSettings.DatasourceSettings.RefreshToken ?
        //Authorization OK
        <div className="scriptitembody">
            {Translate("$Application connection token received succesfully").replace("$Application","Business Central")}
        </div> :
        (mError ?
            //Authorization failed. Show error and connect button
            <div className="scriptitembody">
                <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button><br/>
                <div>{mError}</div>
            </div> :
            (mWaitingForState ?
                //Waiting for authorization. Show dot counter and cancel button
                <div className="scriptitembody">
                    <Button variant="danger" onClick={() => StopOAuth()} type="submit" className="">{Translate("Cancel")}</Button><br/><br/>
                    <div>{Translate("Waiting for OAUTH verification")}<IntegrationWizardDots/></div>
                </div> :
                //Not authenticating. Show connect button
                <div className="scriptitembody">
                    <Button variant="primary" onClick={() => StartOAuth()} type="submit" className="">{Translate("Connect")}</Button>
                </div>
            )
        )

    return (
        <span>{Body}</span>
    )
}

DatasourceBusinessCentral.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceBusinessCentral