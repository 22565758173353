import axios from "axios";

const cConfigurationBaseApi = "https://integration-va.cloudcti.nl/integrationbeta/api/";
const cUseWeb = false //true: show only web / false: show all

export async function ConfigurationApiCallFieldScriptsAdd(aSecurityToken, aCallFieldScripts) // => list of IntegrationRecords
{
  try 
  {
    let integrations = await ConfigurationApiIntegrationGet(aSecurityToken)
    if (integrations === null)
    {
        integrations = []
    }

    const defaultScriptsIntegrations = integrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
    if (defaultScriptsIntegrations && (defaultScriptsIntegrations.length === 1))
    {
      const defaultScriptsIntegration = defaultScriptsIntegrations[0]
      defaultScriptsIntegration.Scripts = [...defaultScriptsIntegration.Scripts, ...aCallFieldScripts]  
      
      const result = await axios.put(
        cConfigurationBaseApi + "Integration",
        {
          IntegrationSettings: defaultScriptsIntegration,
          IntegrationUpdateTypes: "Scripts",
          ComputerName: "",
          WindowsUserName: ""
        },
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      )
      return result.data.InfoRecords
    }
    else
    {
      const integration = {
        IntegrationGuid: "DefaultScripts",
        Scripts: aCallFieldScripts,
        Enabled: true
      }

      const result = await axios.post(
        cConfigurationBaseApi + "Integration",
        {
          IntegrationSettings : integration
        },
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      )
      return result.data.InfoRecords
    }
  } 
  catch (ex) 
  {
    console.log("ConfigurationApiCallFieldScriptsAdd failed: " + ex)
    return null
  }
}

export async function ConfigurationApiCallFieldScriptsDelete(aSecurityToken, aIndex) // => list of IntegrationRecords
{
  try 
  {
    const integrations = await ConfigurationApiIntegrationGet(aSecurityToken)
    if ((integrations !== null) && (integrations !== undefined))
    {
      const defaultScriptsIntegrations = integrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
      if ((defaultScriptsIntegrations !== null) && (defaultScriptsIntegrations !== undefined) && (defaultScriptsIntegrations.length === 1))
      {
        const defaultScriptsIntegration = defaultScriptsIntegrations[0]
        defaultScriptsIntegration.Scripts = defaultScriptsIntegration.Scripts.filter((script,index) => index !== aIndex) 

        const result = await axios.put(
          cConfigurationBaseApi + "Integration",
          {
            IntegrationSettings: defaultScriptsIntegration,
            IntegrationUpdateTypes: "Scripts",
            ComputerName: "",
            WindowsUserName: ""
          },
          {
            headers: {
              Authorization: "Bearer " + aSecurityToken,
              Accept: "application/json"
            },
          }
        );
        return result.data.InfoRecords;
      }
    }
    return null
  } 
  catch (ex) 
  {
    console.log(ex)
    return null
  }
}

export async function ConfigurationApiCallFieldScriptsEnable(aSecurityToken, aIndex, aFlag) // => list of IntegrationRecords
{
  try 
  {
    const integrations = await ConfigurationApiIntegrationGet(aSecurityToken)
    if ((integrations !== null) && (integrations !== undefined))
    {
      const defaultScriptsIntegrations = integrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
      if ((defaultScriptsIntegrations !== null) && (defaultScriptsIntegrations !== undefined) && (defaultScriptsIntegrations.length === 1))
      {
        const defaultScriptsIntegration = defaultScriptsIntegrations[0]
        defaultScriptsIntegration.Scripts[aIndex].Enabled = aFlag

        const result = await axios.put(
          cConfigurationBaseApi + "Integration",
          {
            IntegrationSettings: defaultScriptsIntegration,
            IntegrationUpdateTypes: "Scripts",
            ComputerName: "",
            WindowsUserName: ""
          },
          {
            headers: {
              Authorization: "Bearer " + aSecurityToken,
              Accept: "application/json"
            },
          }
        );
        return result.data.InfoRecords;
      }
    }
    return null
  } 
  catch (ex) 
  {
    console.log(ex)
    return null
  }
}

export async function ConfigurationApiCallFieldScriptsUpdate(aSecurityToken, aCallFieldScripts) // => list of IntegrationRecords
{
  try 
  {
    const integrations = await ConfigurationApiIntegrationGet(aSecurityToken)
    if (integrations)
    {
      const defaultScriptsIntegrations = integrations.filter(integration => integration.IntegrationGuid === "DefaultScripts")
      if (defaultScriptsIntegrations && (defaultScriptsIntegrations.length === 1))
      {
        const defaultScriptsIntegration = defaultScriptsIntegrations[0]
        defaultScriptsIntegration.Scripts = [...aCallFieldScripts]  
        
        const result = await axios.put(
          cConfigurationBaseApi + "Integration",
          {
            IntegrationSettings: defaultScriptsIntegration,
            IntegrationUpdateTypes: "Scripts",
            ComputerName: "",
            WindowsUserName: ""
          },
          {
            headers: {
              Authorization: "Bearer " + aSecurityToken,
              Accept: "application/json"
            },
          }
        )
        return result.data.InfoRecords
      }
      else
      {
        const integration = {
          IntegrationGuid: "DefaultScripts",
          Scripts: aCallFieldScripts,
          Enabled: true
        }

        const result = await axios.post(
          cConfigurationBaseApi + "Integration",
          {
            integration
          },
          {
            headers: {
              Authorization: "Bearer " + aSecurityToken,
              Accept: "application/json"
            },
          }
        )
        return result.data.InfoRecords
      }
    }
    return null
  } 
  catch (ex) 
  {
    console.log("ConfigurationApiCallFieldScriptsAdd failed: " + ex)
    return null
  }
}

export async function ConfigurationApiCheckScheduler(aSecurityToken, aIntegrationGuid) // => bool
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.get(
          cConfigurationBaseApi + "checkscheduler?integrationguid=" + aIntegrationGuid,  
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                },
            })
        return result.status === 204;
      }
      return false
    } 
    catch (ex) 
    {    
        return false;
    }
}

export async function ConfigurationApiCheckToken(aSecurityToken) // => bool
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.get(
          cConfigurationBaseApi + "checktoken",  
            {
                headers: {
                    //"Access-Control-Allow-Origin": "http://localhost",
                    Authorization: "Bearer " + aSecurityToken,
                },
            })
        return result.status === 204;
      }
      return false
    } 
    catch (ex) 
    {    
        return false;
    }
}

export async function ConfigurationApiCRMApplicationsGet(aSecurityToken, aCancelTokenSource) // => List of CRMApplications
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.get(
          cConfigurationBaseApi + "crmapplication?web=" + (cUseWeb ? "true" : "false") + "&beta=" + (window.location.href.includes("beta") || window.location.href.includes("localhost")),  
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                },
            },
            aCancelTokenSource)
        return result.data

        /*
        return result.data.filter(crm => crm.Id !== "ActiveWhere"
          && crm.Id !== "AgencyBloc" 
          && crm.Id !== "Animana" 
          && crm.Id !== "Anva" 
          && crm.Id !== "BaseNet" 
          && crm.Id !== "Brio" 
          && crm.Id !== "Bytegear"
          && crm.Id !== "Codex"
          && crm.Id !== "eLation"
          && crm.Id !== "FoodTec POS"
          && crm.Id !== "EZLynx"
        )
        */
      }
      return null
    } 
    catch (ex) 
    {    
        return null;
    }
}

export async function ConfigurationApiCRMApplicationsGetAll() // => List of CRMApplications
{
    const result = await axios.get(cConfigurationBaseApi + "crmapplication")
    return result.data;
}

export async function ConfigurationApiCRMDatasourcesGet(aSecurityToken, aApplication) // => List of CRMDatasources
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.get(
          cConfigurationBaseApi + "crmdatasource?application=" + aApplication + "&web=" + (cUseWeb ? "true" : "false") + "&beta=" + (window.location.href.includes("beta") || window.location.href.includes("localhost")),
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                },
            })
        return result.data;
      }
      return null
    } 
    catch (ex) 
    {    
        return null
    }
}

export async function ConfigurationApiCRMScriptsGet(aSecurityToken) // => List of CRMScripts
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.get(
          cConfigurationBaseApi + "crmscript?web=" + (cUseWeb ? "true" : "false") + "&beta=" + (window.location.href.includes("beta") || window.location.href.includes("localhost")),
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                },
            })
        return result.data;
      }
      return null
    } 
    catch (ex) 
    {    
        return null;
    }
}

export async function ConfigurationApiDatasourceData(aSecurityToken, aDatasourceType, aDatasourceSettings) // => GetData response from CloudRUCS
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.post(
          cConfigurationBaseApi + "datasourcedata",  
          {
            DatasourceType: aDatasourceType,
            DatasourceSettings: aDatasourceSettings,
            ReturnSize: 50
          },
          {
              headers: {
                  Authorization: "Bearer " + aSecurityToken,
                  Accept: "application/json"
              },
          })
        return result;
      }
      else
      {
        return {error:"Missing securitytoken"}
      }
    } 
    catch (ex) 
    {    
      return {error:ex.response.data}
    }
}

export async function ConfigurationApiDatasourceInfo(aSecurityToken, aDatasourceType, aInfo, aDatasourceSettings) // => GetInfo response from CloudRUCS
{
    try 
    {
      if (aSecurityToken) 
      {
        const result = await axios.post(
          cConfigurationBaseApi + "datasourceinfo",  
          {
            DatasourceType: aDatasourceType,
            DatasourceSettings: aDatasourceSettings,
            Info: aInfo
          },
          {
              headers: {
                  Authorization: "Bearer " + aSecurityToken,
                  Accept: "application/json"
              },
          })
        return result;
      }
      else
      {
        return {error:"Missing securitytoken"}
      }
    } 
    catch (ex) 
    {    
      return {error:ex.response.data}
    }
}

export async function ConfigurationApiForward(aSecurityToken, aUrl, aMethod, aBody, aHeaderAccept, aHeaderContentType)
{
  if (aSecurityToken) 
  {
    try 
    {
      console.log("Call with body " + aBody)
      const result = await axios.post(
        cConfigurationBaseApi + "forward", 
        {
          Url: aUrl,  
          Method: aMethod,  
          Body: aBody,
          HeaderAccept: aHeaderAccept,
          HeaderContentType: aHeaderContentType,
          CustomHeaders:null
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json"
          }
        }
      )
      console.log(JSON.stringify(result))
      return result
    } 
    catch (error) 
    {
      if (error.response != null)
      {
        console.log("Request failed: " + JSON.stringify(error))
      }
      else
      {
        console.log("Request failed")
      }
    }
  }
  return null
}

export async function ConfigurationApiForwardWithCustomHeaders(aSecurityToken, aUrl, aMethod, aBody, aHeaderAccept, aHeaderContentType, aCustomHeaders)
{
  if (aSecurityToken) 
  {
    try 
    {
      console.log("Call with body " + aBody)
      const result = await axios.post(
        cConfigurationBaseApi + "forward", 
        {
          Url: aUrl,  
          Method: aMethod,  
          Body: aBody,
          HeaderAccept: aHeaderAccept,
          HeaderContentType: aHeaderContentType,
          CustomHeaders: aCustomHeaders
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json"
          }
        }
      )
      console.log(JSON.stringify(result))
      return result
    } 
    catch (error) 
    {
      if (error.response != null)
      {
        console.log("Request failed: " + JSON.stringify(error))
      }
      else
      {
        console.log("Request failed")
      }
    }
  }
  return null
}

export async function ConfigurationApiIntegrationCreate(aSecurityToken, aIntegrationSettings) // => NewIntegrationGuid
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.post(
        cConfigurationBaseApi + "Integration",
        {
          IntegrationSettings: aIntegrationSettings,
          ComputerName: "",
          WindowsUserName: ""
        },
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      );
      return result.data.AddedIntegrationGuid;
    }
    return null
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationDelete(aSecurityToken, aIntegrationGuid) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.delete(
        cConfigurationBaseApi + "Integration?integrationguid=" + aIntegrationGuid,
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords; 
    }
    return null
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationGet(aSecurityToken) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.get(
        cConfigurationBaseApi + "Integration",
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords;
    }
    return null
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationGetSingle(aSecurityToken, aIntegrationGuid) // => IntegrationRecord
{
  try 
  {
    const Integrations = await ConfigurationApiIntegrationGet(aSecurityToken)
    if (Integrations) 
    {
      return Integrations.filter((integration) => {return integration.IntegrationGuid === aIntegrationGuid})[0]
    }
    return null
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationGetSyncState(aSecurityToken, aIntegrationGuid) // => ResponseIntegrationStatus
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.get(
        cConfigurationBaseApi + "integration/status?integrationguid=" + aIntegrationGuid,
        {
          headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
          },
        }
      );
      return result.data
    }
    return null
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationEnable(aSecurityToken, aIntegrationGuid, aFlag, aUserName) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.put(
        cConfigurationBaseApi + "integration",  
        {
          IntegrationSettings: {IntegrationGuid: aIntegrationGuid, Enabled: aFlag},
          UpdateType: "Enabled",
          ComputerName: "",
          WindowsUserName: aUserName,
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json",
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords;
    }
    else
    {
      return null
    }
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationSynchronizeNow(aSecurityToken, aIntegrationGuid) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.put(
        cConfigurationBaseApi + "integration",  
        {
          IntegrationSettings: {IntegrationGuid: aIntegrationGuid},
          UpdateType: "Synchronize",
          ComputerName: "",
          WindowsUserName: "",
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json",
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords;
    }
    else
    {
      return null
    }
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationUpdateAll(aSecurityToken, aIntegrationSettings) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.put(
        cConfigurationBaseApi + "integration",  
        {
          IntegrationSettings: aIntegrationSettings,
          UpdateType: "All",
          ComputerName: "",
          WindowsUserName: "",
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json",
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords;
    }
    else
    {
      return null
    }
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiIntegrationUpdateCountryCode(aSecurityToken, aIntegrationSettings) // => List of IntegrationRecords
{
  try 
  {
    if (aSecurityToken) 
    {
      const result = await axios.put(
        cConfigurationBaseApi + "integration",  
        {
          IntegrationSettings: aIntegrationSettings,
          UpdateType: "NumberSettings",
          ComputerName: "",
          WindowsUserName: "",
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json",
            Accept: "application/json"
          },
        }
      );
      return result.data.InfoRecords;
    }
    else
    {
      return null
    }
  } 
  catch (ex) 
  {
    return null
  }
}

export async function ConfigurationApiTriggerScript(aSecurityToken, aCSDFileName, aCSDParams, aCallFields, aRecognitionFields)
{
  if (aSecurityToken) 
  {
    try 
    {
      const result = await axios.post(
        cConfigurationBaseApi + "triggerscript",  
        {
          CSDFilename: aCSDFileName,
          CSDParams: aCSDParams,
          CallFields: aCallFields,
          RecognitionFields: aRecognitionFields,
        },
        {
          headers:
          {
            Authorization: "Bearer " + aSecurityToken,
            ContentType: "application/json",
            Accept: "application/json"
          },
        }
      );
      return ""
    } 
    catch (error) 
    {
      return error.response.data
    }
  }
  else
  {
    return "Securitytoken not found"
  }
}