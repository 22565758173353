import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

const DatasourceFileVine = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    useEffect(() => {
        if (!props.IntegrationSettings.DatasourceSettings.Domain)
        {
            props.IntegrationSettings.DatasourceSettings.Domain = "United States"
        }

        SetReady()
      }, []); 
      
    const UpdateDatasourceSetting = (aValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aValue

        if (aIdentifier === "Domain")
        {
            if (aValue !== "custom")
            {
                props.IntegrationSettings.DatasourceSettings["CompanyName"] = ""
            }
        }

        SetReady()
    }

    const SetReady = () => 
    {
        const Ready = (props.IntegrationSettings.DatasourceSettings["ApiKey"])
            && (props.IntegrationSettings.DatasourceSettings["ApiSecret"])

        props.OnSetReady(Ready)
    }
    
    return (
        <div className="scriptitembody">
            <div className="wizardfieldname">{Translate("API key").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.ApiKey || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ApiKey")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("API secret").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="password" value={props.IntegrationSettings.DatasourceSettings.ApiSecret || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ApiSecret")} className="wizardinputfield"/></div>
            <br/>
            <div className="wizardfieldname"><input type="radio" id="domain" checked={props.IntegrationSettings.DatasourceSettings.Domain !== "custom"} onClick={() => UpdateDatasourceSetting("United States", "Domain")}/> {Translate("Select your FileVine login").toUpperCase()}</div>
            <div className="wizardfieldvalue"><select name="Domain" onChange={evt => UpdateDatasourceSetting("Domain", evt.target.value)} className={props.IntegrationSettings.DatasourceSettings.Domain === "custom" ? "wizardinputfielddisabled" : "wizardinputfield"} disabled={props.IntegrationSettings.DatasourceSettings.Domain === "custom" ? 1 : 0}>
                <option value="United States" selected={props.IntegrationSettings.DatasourceSettings.Domain === "United States"}>app.filevine.com (United States)</option>
                <option value="Canada" selected={props.IntegrationSettings.DatasourceSettings.Domain === "Canada"}>app.filevine.ca (Canada)</option>
            </select></div>
            <br/>
            <div className="wizardfieldname"><input type="radio" id="domain" checked={props.IntegrationSettings.DatasourceSettings.Domain === "custom"} onClick={() => UpdateDatasourceSetting("custom","Domain")}/> {Translate("Use a team domain").toUpperCase()}</div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings["CompanyName"] || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "CompanyName")} className={props.IntegrationSettings.DatasourceSettings.Domain === "custom" ? "wizardinputfield" : "wizardinputfielddisabled"} disabled={props.IntegrationSettings.DatasourceSettings.Domain === "custom" ? 0 : 1}/></div>
        </div>
    );
}

DatasourceFileVine.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceFileVine