import React from "react";
import PropTypes from 'prop-types';

const IntegrationWizardStepScriptItemNoType = (props) => {

    return (
        <div className="scriptitembody">
            Under development. Support for creating new scripts will be added later.
        </div>
    );
}

IntegrationWizardStepScriptItemNoType.propTypes = {
  Script: PropTypes.object
}

export default IntegrationWizardStepScriptItemNoType