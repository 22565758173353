import React from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "./DatasourceBaseTextFields.jsx";
import { ConfigurationApiDatasourceInfo } from "../../ExternalApi/ConfigurationApi"

const DatasourceRedtail = (props) => {

    const Fields = [
        {Identifier:"ApiKey", Translation:Translate("API key"), Required:true},
        {Identifier:"UserName", Translation:Translate("Username"), Required:true},
        {Identifier:"PassWord", Translation:Translate("Password"), Required:true, PasswordField:true}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceRedtail.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceRedtail

export async function Redtail_Finalize(aSecurityToken,aIntegrationSettings) 
{
    try 
    {
        const result = await ConfigurationApiDatasourceInfo(aSecurityToken, aIntegrationSettings.DatasourceType, "UserKey", aIntegrationSettings.DatasourceSettings)
        if (result.status === 200)
        {
            aIntegrationSettings.DatasourceSettings.UserKey = result.data.Result
            return (result.data.Result)
        }
        else
        {
            return false
        }

    } 
    catch (ex) 
    {    
        console.log(ex)
        return false
    }
}