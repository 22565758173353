import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseTextFields from "../Datasources/DatasourceBaseTextFields.jsx";

const DatasourceVTiger = (props) => {

    const Fields = [
        {Identifier:"Url", Translation:"URL", Required:true},
        {Identifier:"UserName", Translation:Translate("Username"), Required:true},
        {Identifier:"AccessKey", Translation:Translate("Access key"), Required:true},
        {Identifier:"RequestsPerMinute", Translation:Translate("Requests per minute"), Required:false, Hint:Translate("Leave empty for maximum")}
    ]
    
    return (<DatasourceBaseTextFields SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} Fields={Fields} OnSetReady={props.OnSetReady}/>)

}

DatasourceVTiger.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceVTiger