import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";

const DatasourceFlexkidsLIVE = (props) => {
    
    const [mRepainter, SetRepainter] = useState(false); 

    const [mCredentialsType, SetCredentialsType] = useState("Company"); 


    useEffect(() => {
        if (!props.IntegrationSettings.DatasourceSettings.Domain)
        {
            props.IntegrationSettings.DatasourceSettings.Domain = "United States"
        }

        SetReady()
      }, []); 

    const UpdateCredentialsType = (aValue) =>
    {
        SetCredentialsType(aValue)

        if (aValue === "Company")
        {
            props.IntegrationSettings.DatasourceSettings.PersonDomain = ""
            props.IntegrationSettings.DatasourceSettings.PersonApplicationType = ""
        }
        else if (aValue === "User")
        {
            props.IntegrationSettings.DatasourceSettings.ConnectorApiToken = ""
        }

        SetReady()
    }
      
    const UpdateDatasourceSetting = (aValue, aIdentifier) =>
    {
        SetRepainter(!mRepainter)
        props.IntegrationSettings.DatasourceSettings[aIdentifier] = aValue

        if (aIdentifier === "Domain")
        {
            if (aValue !== "custom")
            {
                props.IntegrationSettings.DatasourceSettings["CompanyName"] = ""
            }
        }

        SetReady()
    }

    const SetReady = () => 
    {
        const Ready = ((mCredentialsType === "Company") && props.IntegrationSettings.DatasourceSettings["Username"] && props.IntegrationSettings.DatasourceSettings["Password"] && props.IntegrationSettings.DatasourceSettings["ConnectorApiToken"])
            || ((mCredentialsType === "User") && props.IntegrationSettings.DatasourceSettings["Username"] && props.IntegrationSettings.DatasourceSettings["Password"] && props.IntegrationSettings.DatasourceSettings["PersonDomain"] && props.IntegrationSettings.DatasourceSettings["PersonApplicationType"])

        props.OnSetReady(Ready)
    }
    
    return (
        <div className="scriptitembody">

            <div className="wizardfieldvalue"><select name="CredentialsType" onChange={evt => UpdateCredentialsType(evt.target.value)} className={"wizardinputfield"}>
                <option value="Company" selected={mCredentialsType === "Company"}>{Translate("Signin with company credentials")}</option>
                <option value="User" selected={mCredentialsType === "User"}>{Translate("Signin with user credentials")}</option>
            </select></div>
            <br/>
            <div className="wizardfieldname">{Translate("Username").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.Username || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Username")} className="wizardinputfield"/></div>
            <div className="wizardfieldname">{Translate("Password").toUpperCase()} <span className="redtext">*</span></div>
            <div className="wizardfieldvalue"><input autoComplete="new-password" type="password" value={props.IntegrationSettings.DatasourceSettings.Password || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "Password")} className="wizardinputfield"/></div>            
            {mCredentialsType === "Company" ? <div className="wizardfieldname">{Translate("API token").toUpperCase()} <span className="redtext">*</span></div> : ""}
            {mCredentialsType === "Company" ? <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.ConnectorApiToken || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "ConnectorApiToken")} className="wizardinputfield"/></div> : ""}
            {mCredentialsType === "User" ? <div className="wizardfieldname">{Translate("Domain").toUpperCase()} <span className="redtext">*</span></div> : ""}
            {mCredentialsType === "User" ? <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.PersonDomain || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "PersonDomain")} className="wizardinputfield"/></div> : ""}
            {mCredentialsType === "User" ? <div className="wizardfieldname">{Translate("Application type").toUpperCase()} <span className="redtext">*</span></div> : ""}
            {mCredentialsType === "User" ? <div className="wizardfieldvalue"><input autoComplete="new-password" type="text" value={props.IntegrationSettings.DatasourceSettings.PersonApplicationType || ""} onChange={evt => UpdateDatasourceSetting(evt.target.value, "PersonApplicationType")} className="wizardinputfield"/></div> : ""}
        </div>
    );
}

DatasourceFlexkidsLIVE.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceFlexkidsLIVE