import React, { useState } from "react";

const IntegrationWizardDots = (props) => {
    
    let mTimer = null
    
    const [mDots, SetDots] = useState(""); 

    const Poll = () =>
    { 
        SetDots(mDots === "......" ? "." : mDots + ".")
        clearInterval(mTimer)
        mTimer = null
    }

    mTimer = setInterval(()=> Poll(), 1000);
    
    return (
        <span>{mDots}</span>
    );
}

export default IntegrationWizardDots