import React from "react";
import PropTypes from 'prop-types';
import { Translate } from "../Translator";
import DatasourceBaseOauth from "../Datasources/DatasourceBaseOauth.jsx";


const DatasourceOffice = (props) => {

    const AuthUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
        "?client_id=d2b5e92c-dadf-4d33-9dab-b6b5517f8773" +
        "&redirect_uri=https%3A%2F%2Fauth.cloudcti.nl%2Fwebapi%2Foauth2" +
        "&response_type=code" +
        "&response_mode=query" + 
        "&scope=https://graph.microsoft.com/contacts.read+https://graph.microsoft.com/user.read+offline_access"
    const TokenUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/token" 
    const TokenBody = "client_id=d2b5e92c-dadf-4d33-9dab-b6b5517f8773&client_secret=q=d_=950Fxzn3seCwrs/d.8NeF:5cDOS&redirect_uri=https://auth.cloudcti.nl/webapi/oauth2&grant_type=authorization_code&code=" 
    const TokenBodyType = "application/x-www-form-urlencoded"
    const TokenCodeInBody = true
    
    return (<DatasourceBaseOauth SecurityToken={props.SecurityToken} DatasourceData={props.DatasourceData} IntegrationSettings={props.IntegrationSettings} AuthUrl={AuthUrl} TokenUrl={TokenUrl} TokenBody={TokenBody} TokenBodyType={TokenBodyType} TokenCodeInBody={TokenCodeInBody} OnSetReady={props.OnSetReady}/>)

}

DatasourceOffice.propTypes = {
    SecurityToken: PropTypes.string,
    DatasourceData: PropTypes.object,
    IntegrationSettings: PropTypes.object,
    OnSetReady: PropTypes.func
}   

export default DatasourceOffice