import React, {useState} from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import IntegrationWizardStepScriptItemCSD from './IntegrationWizardStepScriptItemCSD.jsx'
import IntegrationWizardStepScriptItemNoType from './IntegrationWizardStepScriptItemNoType.jsx'
import IntegrationWizardStepScriptItemWebPage from './IntegrationWizardStepScriptItemWebPage.jsx'
import { ExecuteScript } from '../ScriptExecuter.jsx'
import { GetLanguageCode, Translate } from "../Translator";

import DeletePng from '../../Assets/Delete.png';
import DeleteDisabledPng from '../../Assets/DeleteDisabled.png';

const IntegrationWizardStepScriptItem = (props) => {

    const [mAutoExecute, SetAutoExecute] = useState(props.Script.AutoExecute) //used only as a repainter
    const [mScriptName, SetScriptName] = useState(props.Script.Name) //used only as a repainter
    const [mScriptTest, SetScriptTest] = useState(false) //modal toggle
    const [mScriptDelete, SetScriptDelete] = useState(false) //modal toggle
    const [mFolded, SetFolded] = useState(props.Folded) 

    const [mCallFieldCallerNumber, SetCallFieldCallerNumber] = useState("0123456789"); 
    const [mCallFieldCallerName, SetCallFieldCallerName] = useState("test number"); 
    const [mCallFieldDeviceNumber, SetCallFieldDeviceNumber] = useState("100"); 
    const [mCallFieldDeviceName, SetCallFieldDeviceName] = useState("test device"); 
    const [mCallFieldDdiNumber, SetCallFieldDdiNumber] = useState("100"); 
    const [mCallFieldDdiName, SetCallFieldDdiName] = useState("test ddi"); 

    const TestScript = () => {

        const CallFields = {
            "Caller number": mCallFieldCallerNumber, 
            "Caller name": mCallFieldCallerName,
            "Device number": mCallFieldDeviceNumber, 
            "Device name": mCallFieldDeviceName, 
            "Ddi number": mCallFieldDdiNumber, 
            "Ddi name": mCallFieldDdiName,
            "Start time": new Date(), 
            "Application name": "" 
        }

        console.log(ExecuteScript(props.SecurityToken, props.Script, CallFields, props.DatasourceFields) ?? "script ok")
        SetScriptTest(false)
    }

    const HelpCaption = () => {
        if (props.PredefinedScript)
        {
            const LanguageCode = GetLanguageCode() ?? "default"  
            if (props.PredefinedScript.Help)
            {
                return props.PredefinedScript.Help[LanguageCode]
            }
        }
        return "";
    }

    const UpdateAutoExecute = (newValue) =>
    {
        props.Script.AutoExecute = (newValue ? true : false)
        SetAutoExecute(newValue)
    }

    const UpdateScriptName = (newValue) =>
    {
        props.Script.Name = newValue
        SetScriptName(newValue)
    }

    const ScriptBody = ((props.Script.ScriptType === "") ? <IntegrationWizardStepScriptItemNoType Script={props.Script}/> :
        (((props.Script.ScriptType === "CSD") || (props.Script.ScriptType === "ServerCSD")) ? <IntegrationWizardStepScriptItemCSD Script={props.Script} PredefinedScript={props.PredefinedScript}/> :
        ((props.Script.ScriptType === "OpenWebPage") ? <IntegrationWizardStepScriptItemWebPage DatasourceFields={props.DatasourceFields} Script={props.Script} PredefinedScript={props.PredefinedScript}/> : 
        <div></div>)))

    const BadScript = (props.Script.ScriptType !== "OpenWebPage") && (props.Script.ScriptType !== "ServerCSD")

    const BrandingColorLight = sessionStorage.getItem("BrandingColorLight")
    const BrandingColorLightFont = sessionStorage.getItem("BrandingColorLightFont")

    return (

        <div>
            <table width="100%" cellPadding="20" cellSpacing="0">
            <tbody>
            <tr>
                <td className="wizardoutercelldark" style={{ backgroundColor: BrandingColorLight, color: BrandingColorLightFont}}>

                    {BadScript ?
                    
                    <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                    <tr>
                        <td width="30"></td>
                        <td width="40" valign="center"><img src={DeletePng} className="wizardscriptitemdelete"/></td>
                        <td width="20" valign="center"></td>
                        <td width="*" valign="center">{props.Script.Name}</td>
                        <td width="300" valign="center" align="right">
                            Editing not available in web app
                        </td>
                    </tr>
                    </tbody>
                    </table>  

                    :

                    mFolded 
                    
                    ?

                    <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                    <tr>
                        <td width="30"></td>
                        <td width="40" valign="center"><img src={DeletePng} className="wizardscriptitemdelete" onClick={() => SetScriptDelete(true)}/></td>
                        <td width="20" valign="center"></td>
                        <td width="*" valign="center">{props.Script.Name}</td>
                        <td width="300" valign="center" align="right">
                            <Button variant="outline-dark" onClick={() => SetScriptTest(true)} className="wizardtestbutton">Test script</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button variant="dark" onClick={() => SetFolded(!mFolded)} className="wizardnextbutton">Edit script</Button>
                        </td>
                    </tr>
                    </tbody>
                    </table>   

                    :

                    <table width="100%" cellPadding="20" cellSpacing="0">
                    <tbody>
                    <tr>
                        <td colSpan="2">
                            {props.Script.SubHeader ? <div className="wizardscriptsubheader">{props.Script.SubHeader}</div> : ""}
                            <div className="wizardfieldname">SCRIPT NAME <span className="redtext">*</span></div>
                            <div className="wizardfieldvalue"><input autoComplete="new-password" placeholder="" type="text" value={props.Script.Name} onChange={evt => UpdateScriptName(evt.target.value)} className="wizardinputfield"/></div>    
                            {ScriptBody}
                            {props.Script.ScriptType === "ServerCSD" ? <span></span> : <br/>}
                            {props.Script.ScriptType === "ServerCSD" ? <span></span> : <div><input type="checkbox" checked={props.Script.AutoExecute ? "checked" : ""} onChange={evt => UpdateAutoExecute(evt.target.checked)}/>&nbsp; {Translate("Auto-execute")}</div>}
                        </td>                   
                    </tr>
                    <tr>
                        <td/>
                        <td align="right" valign="bottom">

                            {props.HideSave ? null :
                             <Button variant="outline-dark" onClick={() => SetFolded(!mFolded)} className="wizardtestbutton">{Translate("Stop editing")}</Button>
                            }
                             &nbsp;&nbsp;&nbsp;&nbsp;
                             <Button variant="outline-dark" onClick={() => SetScriptTest(true)} className="wizardtestbutton">{Translate("Test script")}</Button>
                             &nbsp;&nbsp;&nbsp;&nbsp;
                             <img src={DeletePng} className="wizardscriptitemdelete" onClick={() => SetScriptDelete(true)}/>
                        </td>                   
                    </tr>
                    </tbody>
                    </table>}   

                </td>
            </tr>
            </tbody>
            </table>    

            <br/>
            <Modal animation={false} show={mScriptTest} onHide={() => SetScriptTest(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{Translate("Enter the call field values to test the script with")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width="100%" className="scriptitembody">  
                    <tbody>                
                            <tr>
                                <td width="150">{Translate("Caller number")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldCallerNumber} onChange={evt => SetCallFieldCallerNumber(evt.target.value)}/></td>
                            </tr>           
                            <tr>
                                <td width="150">{Translate("Caller name")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldCallerName} onChange={evt => SetCallFieldCallerName(evt.target.value)}/></td>
                            </tr>           
                            <tr>
                                <td width="150">{Translate("Device number")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldDeviceNumber} onChange={evt => SetCallFieldDeviceNumber(evt.target.value)}/></td>
                            </tr>           
                            <tr>
                                <td width="150">{Translate("Device name")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldDeviceName} onChange={evt => SetCallFieldDeviceName(evt.target.value)}/></td>
                            </tr>           
                            <tr>
                                <td width="150">{Translate("Ddi number")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldDdiNumber} onChange={evt => SetCallFieldDdiNumber(evt.target.value)}/></td>
                            </tr>           
                            <tr>
                                <td width="150">{Translate("Ddi name")}</td> 
                                <td width="*"><input autoComplete="new-password" type="text" class="testscriptinput" value={mCallFieldDdiName} onChange={evt => SetCallFieldDdiName(evt.target.value)}/></td>
                            </tr>
                    </tbody>       
                    </table> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => TestScript()}>{Translate("Execute script")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal animation={false} show={mScriptDelete} onHide={() => SetScriptDelete(false)} centered={true}>
                <Modal.Header className="wizardmodalconfirm">
                    <Modal.Title align="center"><span className="wizardmodalheader" align="center">{Translate("Do you really want to remove this script?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body align="center" className="wizardmodalconfirm">
                    <br/>
                    <Button variant="outline-dark" className="wizardmodalnobutton" onClick={() => SetScriptDelete(false)}>No</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" className="wizardmodalyesbutton" onClick={() => props.OnDelete()}>Yes</Button>
                    <br/>
                </Modal.Body>
            </Modal>
        </div>
    );
}

IntegrationWizardStepScriptItem.propTypes = {
    Folded: PropTypes.bool,
    HideSave: PropTypes.bool,
    SecurityToken: PropTypes.string,
    Script: PropTypes.object,
    PredefinedScript: PropTypes.object,
    DatasourceFields: PropTypes.array,
    OnDelete: PropTypes.func
}

export default IntegrationWizardStepScriptItem